import { AlertProps } from "@mui/material";
import { ILoggedInUserDetails } from "./auth.types";

interface IInitialReducerState {
	loading: boolean;
	success: boolean;
	error: string;
	currentAction: string;
}

// AUTH REDUCER
type AuthReducerCurrentActionType = "loginUser" | "logoutUser";

export interface IAuthReducerState extends IInitialReducerState {
	currentAction: `auth/${AuthReducerCurrentActionType}` | "";
	unauthorizedError: boolean;
	user: ILoggedInUserDetails;
}

// FEEDBACK NOTIFICATION REDUCER
export interface IFeedbackNotificationReducerState {
	show: boolean;
	message: string;
	severity?: AlertProps["severity"];
}

// ERROR HANDLING
export type IErrorResponse =
	| string[]
	| {
			[key: string]: [string];
	  };

export enum ERROR_RESPONSE_API_TYPE_E {
	DEVICE_CREATE_EDIT,
	DEVICE_DELETE
}
