import { Navigate, createBrowserRouter } from "react-router-dom";
import PageLayout from "./PageLayout";
import Customers from "../screens/Customers";
import Login from "../screens/Login";
import Devices from "../screens/Devices";
import Users from "../screens/Users";
import SystemIntegrators from "../screens/SystemIntegrators";
import StatusMonitor from "../screens/StatusMonitor";
import { LicenseInfo } from "@mui/x-date-pickers-pro";

LicenseInfo.setLicenseKey(
	"8d70d9731170b6417efb13bf3e89d1f8Tz05MTc4NCxFPTE3NDkwMjEyODAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to="/login" replace />
	},
	{
		path: "/login",
		element: <Login />
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: "/customers",
				element: <Customers />
			},
			{
				path: "/devices",
				element: <Devices />
			},
			{
				path: "/users",
				element: <Users />
			},
			{
				path: "/system-integrators",
				element: <SystemIntegrators />
			},
			{
				path: "/status-monitor",
				element: <StatusMonitor />
			}
		]
	}
]);
