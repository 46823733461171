import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueFormatterParams
} from "@mui/x-data-grid";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { ROWS_PER_PAGE } from "../../../utils/constants";
import { useGetSocietyDevicesQuery } from "../../../redux/reducers/customers.reducer";
import { IGetSocietyDevicesRequestData, ISocietyConfigDetails } from "../../../types";
import TablePagination from "../../../components/TablePagination";
import CustomerDeviceDetailsDialog from "./CustomerDeviceDetailsDialog";

export interface ICustomerDeviceRowDetails {
	id: number;
	device_name: string;
	barrier_config: number;
	serial_no: string;
	release_version: string;
}

interface IDeviceRegistrationProps {
	societyDetails: ISocietyConfigDetails | null;
	onSubmit: () => void;
	onCancel: () => void;
}

const DevicesListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const DeviceRegistration: FC<IDeviceRegistrationProps> = (props) => {
	const { societyDetails, onSubmit, onCancel } = props;

	const [filters, setFilters] = useState<IGetSocietyDevicesRequestData>({
		page: 1,
		society: societyDetails?.id ?? 0
	});

	const [showDeviceDetailsDialog, setShowDeviceDetailsDialog] = useState<boolean>(false);
	const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<ICustomerDeviceRowDetails | null>(null);

	// APIS
	const {
		data: getSocietyDevicesResponse,
		isLoading: getSocietyDevicesLoading,
		isFetching: getSocietyDevicesFetching,
		refetch: refetchSocietyDevices
	} = useGetSocietyDevicesQuery(filters);

	function handlePageChange(updatedModel: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: updatedModel.page + 1
		}));
	}

	function handleOpenDeviceDetailsDialog(deviceDetails: ICustomerDeviceRowDetails | null) {
		setSelectedDeviceDetails(deviceDetails);
		setShowDeviceDetailsDialog(true);
	}

	function handleCloseDeviceDetailsDialog() {
		setShowDeviceDetailsDialog(false);
		setSelectedDeviceDetails(null);
		refetchSocietyDevices();
	}

	const columnsData: GridColDef[] = [
		{
			field: "device_name",
			headerName: "Device Name",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "barrier_config",
			headerName: "Barrier Configuration",
			flex: 1,
			minWidth: 170,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<number>) =>
				params.value === 0
					? "Single (Entry)"
					: params.value === 1
					? "Single (Exit)"
					: params.value === 2
					? "Double (Entry + Exit)"
					: ""
		},
		{
			field: "serial_no",
			headerName: "Serial No",
			flex: 2,
			minWidth: 200,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ICustomerDeviceRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton onClick={() => handleOpenDeviceDetailsDialog(params.row)}>
						<EditIcon />
					</IconButton>
				</Box>
			)
		}
	];

	function getDevicesList(): ICustomerDeviceRowDetails[] {
		if (!getSocietyDevicesResponse) return [];

		return getSocietyDevicesResponse.results.map((item) => ({
			id: item.id,
			device_name: item.device_name,
			barrier_config: item.barrier_config,
			serial_no: item.device_uuid,
			release_version: item.release_version
		}));
	}

	return (
		<Box className="device-registration-screen-wrapper">
			<Box className="device-registration-header-wrapper">
				<Typography variant="h6" color="var(--color-primary-dark)">
					Linked Devices
				</Typography>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={() => handleOpenDeviceDetailsDialog(null)}
				>
					Add
				</Button>
			</Box>

			<Box className="device-registration-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getDevicesList()}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: DevicesListPagination }}
					hideFooter={(getSocietyDevicesResponse?.count ?? 0) <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					paginationMode="server"
					paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					onPaginationModelChange={handlePageChange}
					rowCount={getSocietyDevicesResponse?.count ?? 0}
					loading={getSocietyDevicesLoading || getSocietyDevicesFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root"
					}}
				/>
			</Box>

			<Box className="add-customer-form-footer">
				<Button variant="text" color="error" sx={{ paddingX: 5 }} onClick={onSubmit}>
					Skip
				</Button>

				<Box className="add-customer-form-actions-wrapper">
					<Button variant="outlined" color="error" sx={{ paddingX: 6 }} onClick={onCancel}>
						Back
					</Button>

					<Button disableElevation variant="contained" color="primary" onClick={onSubmit}>
						Save and Next
					</Button>
				</Box>
			</Box>

			<CustomerDeviceDetailsDialog
				open={showDeviceDetailsDialog}
				deviceDetails={selectedDeviceDetails}
				societyDetails={societyDetails}
				onClose={handleCloseDeviceDetailsDialog}
			/>
		</Box>
	);
};

export default DeviceRegistration;
