import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";

import { ISystemIntegratorRowDetails } from "..";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import {
	ICreateSystemIntegratorRequestData,
	IEditSystemIntegratorRequestData,
	ISystemIntegratorContactDetails
} from "../../../types";
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams } from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import TablePagination from "../../../components/TablePagination";
import ContactInfoDetailsDialog from "./ContactInfoDetailsDialog";
import {
	useCreateSystemIntegratorMutation,
	useEditSystemIntegratorMutation
} from "../../../redux/reducers/systemIntegrators.reducer";
import { useDropzone } from "react-dropzone";
import NumberInput from "../../../components/NumberInput";
import { BASE_API_URL } from "../../../utils/apiUtils";

interface ISystemIntegratorDetailsDialogProps {
	open: boolean;
	systemIntegratorDetails: ISystemIntegratorRowDetails | null;
	onClose: () => void;
}

enum SYSTEM_INTEGRATOR_DETAILS_TAB_E {
	SI_DETAILS,
	CONTACT_DETAILS
}

type SystemIntegratorDetailsTabRendererType = {
	[key in SYSTEM_INTEGRATOR_DETAILS_TAB_E]: ReactNode;
};

interface SystemIntegratorFormFields {
	name: string;
	areas_covered: string;
	address: string;
	city: string;
	state: string;
	designation: string;
	support_contact_email: string;
	phone_number: string;
	partner_logo: string | null;
}

const SystemIntegratorsListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const SystemIntegratorDetailsDialog: FC<ISystemIntegratorDetailsDialogProps> = (props) => {
	const { open, systemIntegratorDetails, onClose } = props;

	const [currentTab, setCurrentTab] = useState<SYSTEM_INTEGRATOR_DETAILS_TAB_E>(
		SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS
	);

	const [contactInfoList, setContactInfoList] = useState<ISystemIntegratorContactDetails[]>([]);
	const [showContactInfoDialog, setShowContactInfoDialog] = useState<boolean>(false);
	const [selectedContactIndex, setSelectedContactIndex] = useState<number>(-1);

	// APIS
	const [
		createSystemIntegrator,
		{
			isLoading: createSystemIntegratorLoading,
			isSuccess: createSystemIntegratorSuccess,
			error: createSystemIntegratorError,
			reset: resetCreateSystemIntegratorState
		}
	] = useCreateSystemIntegratorMutation();

	const [
		editSystemIntegrator,
		{
			isLoading: editSystemIntegratorLoading,
			isSuccess: editSystemIntegratorSuccess,
			error: editSystemIntegratorError,
			reset: resetEditSystemIntegratorState
		}
	] = useEditSystemIntegratorMutation();

	const {
		reset,
		watch,
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<SystemIntegratorFormFields>();

	const selectedLogo = watch("partner_logo");

	function handleChangeCurrentTab(updatedValue: SYSTEM_INTEGRATOR_DETAILS_TAB_E) {
		setCurrentTab(updatedValue);
	}

	function convertImageToBase64(imageFile: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(imageFile);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = reject;
		});
	}

	function handleSubmitForm(formData: SystemIntegratorFormFields) {
		if (currentTab === SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS) {
			setCurrentTab(SYSTEM_INTEGRATOR_DETAILS_TAB_E.CONTACT_DETAILS);
			return;
		}

		if (systemIntegratorDetails) {
			const editSystemIntegratorData: IEditSystemIntegratorRequestData = {
				id: systemIntegratorDetails.id,
				data: {
					name: formData.name,
					city: formData.city,
					state: formData.state,
					address: formData.address,
					area_covered: [],
					contact_details: [],
					logo: formData.partner_logo ?? "",
					support_email: formData.support_contact_email,
					support_phone_number: formData.phone_number
				}
			};

			if (formData.areas_covered) {
				const splitAreas = formData.areas_covered.split(",");
				editSystemIntegratorData.data.area_covered.push(...splitAreas.map((item) => item.trim()));
			}

			if (contactInfoList.length > 0) {
				editSystemIntegratorData.data.contact_details.push(
					...contactInfoList.map((item) => ({
						name: item.name,
						// role: item.role,
						contact_number: item.contact_number,
						is_primary_user: item.is_primary_user,
						designation: item.designation
					}))
				);
			}

			editSystemIntegrator(editSystemIntegratorData);
		} else {
			const createSystemIntegratorData: ICreateSystemIntegratorRequestData = {
				name: formData.name,
				city: formData.city,
				state: formData.state,
				address: formData.address,
				area_covered: [],
				contact_details: [],
				logo: formData.partner_logo ?? "",
				support_email: formData.support_contact_email,
				support_phone_number: formData.phone_number
			};

			if (formData.areas_covered) {
				const splitAreas = formData.areas_covered.split(",");
				createSystemIntegratorData.area_covered.push(...splitAreas.map((item) => item.trim()));
			}

			if (contactInfoList.length > 0) {
				createSystemIntegratorData.contact_details.push(
					...contactInfoList.map((item) => ({
						name: item.name,
						// role: item.role,
						contact_number: item.contact_number,
						is_primary_user: item.is_primary_user,
						designation: item.designation
					}))
				);
			}

			createSystemIntegrator(createSystemIntegratorData);
		}
	}

	function handleOpenContactInfoDetailsDialog(index: number) {
		setSelectedContactIndex(index);
		setShowContactInfoDialog(true);
	}

	function handleCloseContactInfoDetailsDialog() {
		setShowContactInfoDialog(false);
		setSelectedContactIndex(-1);
	}

	function handleSubmitContactInfo(contactInfo: ISystemIntegratorContactDetails, index: number) {
		setContactInfoList((currentContactInfoList) => {
			const updatedContactInfoList = [...currentContactInfoList];

			if (index >= 0) {
				updatedContactInfoList[index] = contactInfo;
			} else {
				updatedContactInfoList.push(contactInfo);
			}

			return updatedContactInfoList;
		});

		handleCloseContactInfoDetailsDialog();
	}

	function handleDeleteContact(index: number) {
		setContactInfoList((currentContactInfoList) => {
			const updatedContactInfoList = [...currentContactInfoList];

			if (index >= 0) {
				updatedContactInfoList.splice(index, 1);
			}

			return updatedContactInfoList;
		});

		handleCloseContactInfoDetailsDialog();
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			"image/png": [".png"],
			"image/jpeg": [".jpeg", ".jpg"]
		},
		useFsAccessApi: false,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles && acceptedFiles[0]) {
				convertImageToBase64(acceptedFiles[0]).then((response) => {
					setValue("partner_logo", response);
				});
			}
		},
		maxSize: 1000000
	});

	useEffect(() => {
		if (open) {
			setCurrentTab(SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS);

			if (systemIntegratorDetails) {
				reset({
					name: systemIntegratorDetails.name,
					areas_covered: systemIntegratorDetails.areas_covered,
					address: systemIntegratorDetails.address,
					city: systemIntegratorDetails.city,
					state: systemIntegratorDetails.state,
					designation: systemIntegratorDetails.designation,
					partner_logo: systemIntegratorDetails.partner_logo,
					support_contact_email: systemIntegratorDetails.support_email,
					phone_number: systemIntegratorDetails.support_phone_number
				});

				setContactInfoList(systemIntegratorDetails.contacts);
			} else {
				reset({
					name: "",
					areas_covered: "",
					address: "",
					city: "",
					state: "",
					designation: "",
					partner_logo: null,
					phone_number: "",
					support_contact_email: ""
				});
				setContactInfoList([]);
			}
		} else {
			resetCreateSystemIntegratorState();
			resetEditSystemIntegratorState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, reset, systemIntegratorDetails]);

	useEffect(() => {
		if (createSystemIntegratorSuccess) {
			onClose();
		}
	}, [createSystemIntegratorSuccess, onClose]);

	useEffect(() => {
		if (editSystemIntegratorSuccess) {
			onClose();
		}
	}, [editSystemIntegratorSuccess, onClose]);

	const columnsData: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		// {
		// 	field: "role",
		// 	headerName: "Role",
		// 	flex: 1,
		// 	minWidth: 150,
		// 	sortable: false
		// },
		{
			field: "designation",
			headerName: "Designation",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ISystemIntegratorContactDetails>) => (
				<Box className="system-integrators-table-row-actions">
					<IconButton
						color="inherit"
						size="small"
						onClick={() => {
							const index = contactInfoList.findIndex((item) => item.contact_number === params.row.contact_number);
							handleOpenContactInfoDetailsDialog(index ?? -1);
						}}
					>
						<EditIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	const TabRenderer: SystemIntegratorDetailsTabRendererType = {
		[SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS]: (
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<InputLabel
						required
						htmlFor="name-input"
						error={!!errors.name}
						classes={{ root: "user-form-input-label-root" }}
					>
						System Integrator
					</InputLabel>

					<TextField
						required
						fullWidth
						size="small"
						variant="filled"
						id="name-input"
						placeholder="Add name of the System Integrator"
						error={!!errors.name}
						helperText={errors.name && errors.name.message ? errors.name.message.toString() : ""}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("name", { required: "System integrator name is required" })}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						required
						htmlFor="areas-covered-input"
						error={!!errors.areas_covered}
						classes={{ root: "user-form-input-label-root" }}
					>
						Area(s) Covered
					</InputLabel>

					<TextField
						required
						fullWidth
						size="small"
						variant="filled"
						id="areas-covered-input"
						placeholder="Add location(s) covered by the SI"
						error={!!errors.areas_covered}
						helperText={
							errors.areas_covered && errors.areas_covered.message ? errors.areas_covered.message.toString() : ""
						}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("areas_covered", { required: "Areas covered is required" })}
					/>
				</Grid>

				{/* <Grid item xs={12} md={4}>
					<InputLabel
						required
						htmlFor="designation-input"
						error={!!errors.areas_covered}
						classes={{ root: "user-form-input-label-root" }}
					>
						Designation
					</InputLabel>

					<TextField
						required
						fullWidth
						size="small"
						variant="filled"
						id="designation-input"
						placeholder="Add location(s) covered by the SI"
						error={!!errors.areas_covered}
						helperText={
							errors.areas_covered && errors.areas_covered.message ? errors.areas_covered.message.toString() : ""
						}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("areas_covered", { required: "Areas covered is required" })}
					/>
				</Grid> */}

				<Grid item xs={12}>
					<InputLabel
						required
						htmlFor="address-input"
						error={!!errors.address}
						classes={{ root: "user-form-input-label-root" }}
					>
						Address
					</InputLabel>

					<TextField
						required
						fullWidth
						size="small"
						variant="filled"
						id="address-input"
						placeholder="Add postal address of the System Integrator"
						error={!!errors.address}
						helperText={errors.address && errors.address.message ? errors.address.message.toString() : ""}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("address", { required: "Address is required" })}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						required
						htmlFor="city-input"
						error={!!errors.city}
						classes={{ root: "user-form-input-label-root" }}
					>
						City
					</InputLabel>

					<TextField
						required
						fullWidth
						size="small"
						variant="filled"
						id="city-input"
						placeholder="Add city of the System Integrator"
						error={!!errors.city}
						helperText={errors.city && errors.city.message ? errors.city.message.toString() : ""}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("city", { required: "City is required" })}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						required
						htmlFor="state-input"
						error={!!errors.state}
						classes={{ root: "user-form-input-label-root" }}
					>
						State
					</InputLabel>

					<TextField
						required
						fullWidth
						size="small"
						variant="filled"
						id="state-input"
						placeholder="Add state of the System Integrator"
						error={!!errors.state}
						helperText={errors.state && errors.state.message ? errors.state.message.toString() : ""}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("state", { required: "State is required" })}
					/>
				</Grid>

				<Grid item md={4} display={{ xs: "none", md: "block" }} />

				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="support-contact-email-input"
						error={!!errors.support_contact_email}
						classes={{ root: "user-form-input-label-root" }}
					>
						Support Contact Email
					</InputLabel>

					<TextField
						fullWidth
						size="small"
						variant="filled"
						id="support-contact-email-input"
						placeholder="Add support email"
						error={!!errors.support_contact_email}
						helperText={
							errors.support_contact_email && errors.support_contact_email.message
								? errors.support_contact_email.message.toString()
								: ""
						}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("support_contact_email")}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="phone-number-input"
						error={!!errors.phone_number}
						classes={{ root: "user-form-input-label-root" }}
					>
						Phone Number
					</InputLabel>

					<NumberInput
						fullWidth
						isContactNumber
						size="small"
						variant="filled"
						id="phone-number-input"
						placeholder="Add phone number"
						error={!!errors.phone_number}
						helperText={
							errors.phone_number && errors.phone_number.message ? errors.phone_number.message.toString() : ""
						}
						InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
						{...register("phone_number")}
					/>
				</Grid>

				<Grid item md={4} display={{ xs: "none", md: "block" }} />

				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="solution-partner-logo-input"
						error={!!errors.partner_logo}
						classes={{ root: "user-form-input-label-root" }}
					>
						Upload Solution Partner Logo
					</InputLabel>

					<Box className="upload-support-logo-drop-area-wrapper" {...getRootProps()}>
						<FileUploadIcon color="primary" fontSize="medium" />
						<Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
							{isDragActive ? "Drop here to upload" : "Drag and drop files"}
						</Typography>

						<Box>
							<input
								{...getInputProps()}
								type="file"
								className="d-none"
								name="xslxFile"
								id="xslxFile"
								accept=".png, .jpeg, .jpg"
								onChange={(event) => {
									if (event.target.files && event.target.files[0]) {
										convertImageToBase64(event.target.files[0]).then((response) => {
											setValue("partner_logo", response);
										});
									}
								}}
							/>
						</Box>
					</Box>

					<FormHelperText>Maximum file size: 1 MB</FormHelperText>
				</Grid>

				{selectedLogo ? (
					<Grid item xs={12} md={4}>
						<Box sx={{ height: "100%", width: "fit-content", display: "flex", alignItems: "center" }}>
							<Box sx={{ position: "relative", height: "70px", padding: "0.25rem" }}>
								<img
									src={
										selectedLogo.startsWith("http")
											? selectedLogo
											: selectedLogo.startsWith("data:image")
											? selectedLogo
											: `${BASE_API_URL.replace("/api/v1", "")}${selectedLogo}`
									}
									alt="Solution Partner Logo"
									height="100%"
								/>

								<DeleteIcon
									sx={{ fontSize: "16px", cursor: "pointer", position: "absolute", top: 0, left: "100%" }}
									onClick={() => setValue("partner_logo", null)}
									color="error"
								/>
							</Box>
						</Box>
					</Grid>
				) : null}
			</Grid>
		),
		[SYSTEM_INTEGRATOR_DETAILS_TAB_E.CONTACT_DETAILS]: (
			<Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
				<Box className="system-integrator-contact-header">
					<Typography color="var(--color-primary-main)">Contact Information</Typography>

					<Button
						variant="outlined"
						color="success"
						startIcon={<AddCircleIcon />}
						sx={{ paddingX: 3 }}
						onClick={() => handleOpenContactInfoDetailsDialog(-1)}
					>
						Add
					</Button>
				</Box>

				<DataGrid
					getRowId={(row: ISystemIntegratorContactDetails) => row.contact_number}
					columns={columnsData}
					rows={contactInfoList}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: SystemIntegratorsListPagination }}
					hideFooter={contactInfoList.length <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root",
						virtualScroller: "table-virtual-scroller"
					}}
				/>
			</Box>
		)
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				{systemIntegratorDetails ? "System Integrator Details" : "Add New System Integrator"}

				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box className="dialog-tab-selection-wrapper">
					<Button
						disableElevation
						size="small"
						variant={currentTab === SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS ? "contained" : "outlined"}
						color="primary"
						onClick={() => handleChangeCurrentTab(SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS)}
						startIcon={<ApartmentIcon />}
					>
						SI Details
					</Button>

					<Button
						disableElevation
						size="small"
						variant={currentTab === SYSTEM_INTEGRATOR_DETAILS_TAB_E.CONTACT_DETAILS ? "contained" : "outlined"}
						color="primary"
						onClick={() => handleChangeCurrentTab(SYSTEM_INTEGRATOR_DETAILS_TAB_E.CONTACT_DETAILS)}
						startIcon={<ContactsIcon />}
					>
						Contact Details
					</Button>
				</Box>

				<Box
					sx={{ marginTop: "1rem", display: "flex", flexDirection: "column", gap: "1rem" }}
					component="form"
					noValidate
					onSubmit={handleSubmit(handleSubmitForm)}
				>
					{TabRenderer[currentTab]}

					{createSystemIntegratorError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{"data" in createSystemIntegratorError && createSystemIntegratorError.data
									? `${String(createSystemIntegratorError.data).charAt(0).toUpperCase()}${String(
											createSystemIntegratorError.data
									  ).slice(1)}`
									: "Something went wrong"}
							</Typography>
						</Grid>
					) : null}

					{editSystemIntegratorError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{"data" in editSystemIntegratorError && editSystemIntegratorError.data
									? `${String(editSystemIntegratorError.data).charAt(0).toUpperCase()}${String(
											editSystemIntegratorError.data
									  ).slice(1)}`
									: "Something went wrong"}
							</Typography>
						</Grid>
					) : null}

					{systemIntegratorDetails ? (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
							<LoadingButton
								disableElevation
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}
								loadingPosition="start"
								type="submit"
								loading={editSystemIntegratorLoading}
							>
								Save
							</LoadingButton>
						</Box>
					) : (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
							<LoadingButton
								disableElevation
								variant="contained"
								color="success"
								sx={{ paddingX: 4 }}
								loading={createSystemIntegratorLoading}
								type="submit"
							>
								{currentTab === SYSTEM_INTEGRATOR_DETAILS_TAB_E.SI_DETAILS ? "Next" : "Save"}
							</LoadingButton>
						</Box>
					)}
				</Box>
			</DialogContent>

			<ContactInfoDetailsDialog
				open={showContactInfoDialog}
				// contactInfo={selectedContactInfo}
				contactsList={contactInfoList}
				contactIndex={selectedContactIndex}
				onClose={handleCloseContactInfoDetailsDialog}
				onSubmit={handleSubmitContactInfo}
				onDelete={handleDeleteContact}
			/>
		</Dialog>
	);
};

export default SystemIntegratorDetailsDialog;
