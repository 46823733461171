import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, InputLabel } from "@mui/material";
import React, { FC, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FieldValues, useForm } from "react-hook-form";
import NumberInput from "../NumberInput";

interface IAddFlatDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (flatNumber: string) => void;
}

const AddFlatDialog: FC<IAddFlatDialogProps> = (props) => {
	const { open, onClose, onSubmit } = props;

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	function handleSubmitForm(data: FieldValues) {
		onSubmit(String(data.flat_number));
	}

	useEffect(() => {
		if (open) {
			reset({
				flat_number: ""
			});
		}
	}, [open, reset]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Add Flat
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Box>
						<InputLabel
							required
							error={!!errors.flat_number}
							htmlFor="add-flat-flat-number-input"
							sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
						>
							Flat Number
						</InputLabel>

						<NumberInput
							required
							fullWidth
							positiveOnly
							disableSigned
							disableDecimal
							size="small"
							variant="filled"
							id="add-flat-flat-number-input"
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
							error={!!errors.flat_number}
							helperText={errors.flat_number && errors.flat_number.message ? errors.flat_number.message.toString() : ""}
							{...register("flat_number", { required: "Flat number is required" })}
						/>
					</Box>

					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem" }}>
						<Button disableElevation variant="contained" color="success" type="submit">
							Submit
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default AddFlatDialog;
