import { UnknownAction, combineReducers } from "redux";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "localforage";

import authReducer from "./reducers/auth.reducer";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import feedbackNotificationReducer from "./reducers/feedbackNotification.reducer";
import { queriesApi } from "./reducers/queries.reducer";

const rootReducer = combineReducers({
	[authReducer.name]: persistReducer(
		{ key: authReducer.name, storage, blacklist: ["loading", "success", "error", "currentAction"] },
		authReducer.reducer
	),
	[feedbackNotificationReducer.name]: feedbackNotificationReducer.reducer,
	// RTK REDUCERS
	[queriesApi.reducerPath]: queriesApi.reducer
});

type ReducerStateType = Parameters<typeof rootReducer>[0];

export const store = configureStore({
	reducer: (state: ReducerStateType, action: UnknownAction) => {
		const actionError = action.error ? (action.error as { message?: string }) : undefined;
		const actionPayload = action.payload ? (action.payload as { status?: number }) : undefined;

		if (
			(actionError && actionError.message && actionError.message === "Invalid token.") ||
			(actionPayload && actionPayload.status === 401)
		) {
			const updatedState: ReducerStateType = state ? { ...state } : undefined;

			const authReducerName = authReducer.name;

			if (updatedState && updatedState[authReducerName]) {
				updatedState[authReducerName] = {
					...updatedState[authReducerName],
					loading: false,
					success: false,
					error: "",
					unauthorizedError: true,
					user: {
						...updatedState[authReducerName].user,
						token: "invalid"
					}
				};
			}

			return rootReducer(updatedState, action);
		}

		return rootReducer(state, action);
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		}).concat(queriesApi.middleware)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
