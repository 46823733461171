import React, { FC, useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { ISystemIntegratorContactDetails } from "../../../types";
import { Controller, useForm } from "react-hook-form";
import NumberInput from "../../../components/NumberInput";
import { LoadingButton } from "@mui/lab";

interface IContactInfoDetailsDialogProps {
	open: boolean;
	// contactInfo: ISystemIntegratorContactDetails | null;
	contactsList: ISystemIntegratorContactDetails[];
	contactIndex: number;
	onClose: () => void;
	onSubmit: (contactDetails: ISystemIntegratorContactDetails, index: number) => void;
	onDelete: (index: number) => void;
}

const ContactInfoDetailsDialog: FC<IContactInfoDetailsDialogProps> = (props) => {
	const { open, contactsList, contactIndex, onClose, onSubmit, onDelete } = props;

	const [formError, setFormError] = useState<string>("");

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<ISystemIntegratorContactDetails>();

	function handleSubmitForm(formData: ISystemIntegratorContactDetails) {
		if (formData.contact_number) {
			const foundIndex = contactsList.findIndex(
				(item) => String(item.contact_number) === String(formData.contact_number)
			);

			if (foundIndex >= 0 && foundIndex !== contactIndex) {
				setFormError("This contact number already exists");
				return;
			}
		}

		if (formData.is_primary_user) {
			const foundIndex = contactsList.findIndex((item) => item.is_primary_user);

			if (foundIndex >= 0 && foundIndex !== contactIndex) {
				setFormError("A primary user already exists for this System Integrator");
				return;
			}
		}

		onSubmit(
			{
				...formData
				// role: CUSTOMER_ROLE_E[Number(formData.role)]
			},
			contactIndex
		);
	}

	useEffect(() => {
		if (open) {
			if (contactIndex >= 0) {
				// const selectedRole = Object.entries(CUSTOMER_ROLE_E).find(([key]) => key === contactInfo.role);
				const contactInfo = contactsList[contactIndex];

				if (contactInfo) {
					reset({
						name: contactInfo.name,
						contact_number: contactInfo.contact_number,
						// role: selectedRole && selectedRole.length === 2 ? String(selectedRole[1]) : "",
						is_primary_user: contactInfo.is_primary_user
					});
				}
			} else {
				reset({
					name: "",
					contact_number: "",
					// role: "",
					is_primary_user: false
				});
			}
		} else {
			setFormError("");
		}
	}, [contactIndex, contactsList, open, reset]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				{contactIndex >= 0 ? "Contact Information" : "Add New Contact Information"}

				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="name-input"
								error={!!errors.name}
								classes={{ root: "user-form-input-label-root" }}
							>
								Name of the person
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="name-input"
								placeholder="Add username of the person"
								error={!!errors.name}
								helperText={errors.name && errors.name.message ? errors.name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("name", { required: "Name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="contact-number-input"
								error={!!errors.name}
								classes={{ root: "user-form-input-label-root" }}
							>
								Contact Number
							</InputLabel>

							<NumberInput
								isContactNumber
								positiveOnly
								disableSigned
								disableDecimal
								required
								fullWidth
								size="small"
								variant="filled"
								id="contact-number-input"
								placeholder="Add contact number of the person"
								error={!!errors.contact_number}
								helperText={
									errors.contact_number && errors.contact_number.message ? errors.contact_number.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("contact_number", { required: "Contact number is required" })}
							/>
						</Grid>

						{/* <Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="role-input"
								error={!!errors.role}
								classes={{ root: "user-form-input-label-root" }}
							>
								Role
							</InputLabel>

							<Controller
								name="role"
								control={control}
								rules={{ required: "Role is required" }}
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={CUSTOMER_ROLES_LIST}
										getOptionLabel={(option) => option.label}
										getOptionKey={(option) => option.key}
										value={CUSTOMER_ROLES_LIST.find((item) => item.key === Number(value)) ?? null}
										onChange={(_, updatedValue) => onChange(updatedValue?.key ?? null)}
										id="role-input"
										renderInput={(params) => (
											<TextField
												{...params}
												required
												fullWidth
												hiddenLabel
												size="small"
												variant="filled"
												placeholder="Enter the Role"
												error={!!errors.role}
												helperText={errors.role && errors.role.message ? errors.role.message.toString() : ""}
												InputProps={{
													...params.InputProps,
													hiddenLabel: true,
													disableUnderline: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid> */}

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="designation-input"
								error={!!errors.designation}
								classes={{ root: "user-form-input-label-root" }}
							>
								Designation
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="designation-input"
								placeholder="Add designation of the person"
								error={!!errors.designation}
								helperText={
									errors.designation && errors.designation.message ? errors.designation.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("designation", { required: "Designation is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								htmlFor="primary-user-input"
								error={!!errors.is_primary_user}
								classes={{ root: "user-form-input-label-root" }}
							>
								Primary User
							</InputLabel>

							<Controller
								control={control}
								name="is_primary_user"
								render={({ field: { value, onChange } }) => (
									<FormControl fullWidth>
										<FormControlLabel
											control={<Checkbox checked={value} onChange={(_event, checked) => onChange(checked)} />}
											label="Primary User"
										/>
									</FormControl>
								)}
							/>
						</Grid>

						{formError ? (
							<Grid item xs={12}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Typography variant="body2" color="var(--color-error-main)">
										{formError}
									</Typography>
								</Box>
							</Grid>
						) : null}

						<Grid item xs={12}>
							{contactIndex >= 0 ? (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<LoadingButton
										variant="text"
										color="error"
										startIcon={<DeleteOutlineIcon />}
										onClick={() => onDelete(contactIndex)}
									>
										Delete User
									</LoadingButton>

									<Button disableElevation variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
										Save
									</Button>
								</Box>
							) : (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Button disableElevation variant="contained" color="success" sx={{ paddingX: 4 }} type="submit">
										Save
									</Button>
								</Box>
							)}
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ContactInfoDetailsDialog;
