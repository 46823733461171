import { getAPIHeaderOptions, getDefaultParams } from "../../utils/apiUtils";
import {
	ICreateSocietyRequestData,
	IEditSocietyRequestData,
	IGetAllCustomersResponseDetails,
	IGetSocietyConfigResponseData,
	ISocietyConfigDetails
} from "../../types";
import { queriesApi } from "./queries.reducer";
import { handleTransformAPIErrorResponse } from "../../utils/commonUtils";

export const settings = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET SOCIETY CONFIG
		getSocietyConfig: builder.query<IGetSocietyConfigResponseData, void>({
			query: () => ({
				url: "/analytics/admin/society",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		}),

		// EDIT SOCIETY CONFIG
		editSocietyConfig: builder.mutation<ISocietyConfigDetails, IEditSocietyRequestData>({
			query: (requestData) => ({
				url: `/analytics/admin/society/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: { ...getDefaultParams(), ...requestData.data }
			}),
			transformErrorResponse: handleTransformAPIErrorResponse
		}),

		// CREATE SOCIETY
		createSociety: builder.mutation<ISocietyConfigDetails, ICreateSocietyRequestData>({
			query: (requestData) => ({
				url: "/analytics/admin/society",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: { ...getDefaultParams(), ...requestData }
			}),
			transformErrorResponse: handleTransformAPIErrorResponse
		}),

		// GET ALL CUSTOMERS
		getAllCustomers: builder.query<IGetAllCustomersResponseDetails[], void>({
			query: () => ({
				url: "/analytics/admin/list-societies",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		})
	})
});

export const {
	// GET SOCIETY CONFIG
	useGetSocietyConfigQuery,
	// EDIT SOCIETY CONFIG
	useEditSocietyConfigMutation,
	// CREATE SOCIETY
	useCreateSocietyMutation,
	// GET ALL CUSTOMERS
	useGetAllCustomersQuery
} = settings;
