import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";

import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueFormatterParams,
	GridValueGetterParams
} from "@mui/x-data-grid";
import { CUSTOMER_ROLE_E, ROWS_PER_PAGE } from "../../../utils/constants";
import { useGetSocietyUsersQuery } from "../../../redux/reducers/customers.reducer";
import { IGetSocietyUsersRequestData, ISocietyConfigDetails } from "../../../types";
import TablePagination from "../../../components/TablePagination";
import CustomerUserDetailsDialog from "./CustomerUserDetailsDialog";
import { convertToTitleCase } from "../../../utils/commonUtils";

interface IUserDetailsProps {
	societyDetails: ISocietyConfigDetails | null;
	onSubmit: () => void;
	onCancel: () => void;
}

export interface ISocietyUserRowDetails {
	id: number;
	first_name: string;
	last_name: string;
	login_id: string;
	customer_name: string;
	contact_number: string;
	role: number;
	designation: string;
	email: string;
	address: string;
	allow_secure_login: boolean;
}

const UsersListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const UserDetails: FC<IUserDetailsProps> = (props) => {
	const { societyDetails, onSubmit, onCancel } = props;

	const [filters, setFilters] = useState<IGetSocietyUsersRequestData>({
		page: 1,
		society: societyDetails?.id ?? 0
	});

	const [showUserDetailsDialog, setShowUserDetailsDialog] = useState<boolean>(false);
	const [selectedUserDetails, setSelectedUserDetails] = useState<ISocietyUserRowDetails | null>(null);

	// APIS
	const {
		data: getSocietyUsersResponse,
		isLoading: getSocietyUsersLoading,
		isFetching: getSocietyUsersFetching,
		refetch: refetchSocietyUsers
	} = useGetSocietyUsersQuery(filters);

	function handlePageChange(updatedModel: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: updatedModel.page + 1
		}));
	}

	function handleOpenUserDetailsDialog(userDetails: ISocietyUserRowDetails | null) {
		setSelectedUserDetails(userDetails);
		setShowUserDetailsDialog(true);
	}

	function handleCloseUserDetailsDialog() {
		setShowUserDetailsDialog(false);
		setSelectedUserDetails(null);
		refetchSocietyUsers();
	}

	const columnsData: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 150,
			sortable: false,
			valueGetter: (params: GridValueGetterParams<ISocietyUserRowDetails>) =>
				`${params.row.first_name} ${params.row.last_name}`
		},
		{
			field: "login_id",
			headerName: "Login ID",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "customer_name",
			headerName: "Customer Name",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "contact_number",
			headerName: "Customer Number",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "role",
			headerName: "Role",
			flex: 1,
			minWidth: 150,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<number>) => convertToTitleCase(CUSTOMER_ROLE_E[params.value])
		},
		{
			field: "designation",
			headerName: "Designation",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ISocietyUserRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton onClick={() => handleOpenUserDetailsDialog(params.row)}>
						<EditIcon />
					</IconButton>
				</Box>
			)
		}
	];

	function getUsersList(): ISocietyUserRowDetails[] {
		if (!getSocietyUsersResponse) return [];

		return getSocietyUsersResponse.results.map((userItem) => ({
			id: userItem.id,
			first_name: userItem.first_name,
			last_name: userItem.last_name,
			login_id: userItem.username,
			contact_number: userItem.phone_number,
			customer_name: societyDetails?.name ?? "",
			role: userItem.role,
			designation: userItem.designation,
			email: userItem.email,
			address: userItem.address,
			allow_secure_login: userItem.allow_secure_login
		}));
	}

	return (
		<Box className="device-registration-screen-wrapper">
			<Box className="device-registration-header-wrapper">
				<Typography variant="h6" color="var(--color-primary-dark)">
					User Data
				</Typography>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={() => handleOpenUserDetailsDialog(null)}
				>
					Add
				</Button>
			</Box>

			<Box className="device-registration-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getUsersList()}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: UsersListPagination }}
					hideFooter={(getSocietyUsersResponse?.count ?? 0) <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					paginationMode="server"
					paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					onPaginationModelChange={handlePageChange}
					rowCount={getSocietyUsersResponse?.count ?? 0}
					loading={getSocietyUsersLoading || getSocietyUsersFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root"
					}}
				/>
			</Box>

			<Box className="add-customer-form-footer">
				<Button variant="text" color="error" sx={{ paddingX: 5 }} onClick={onSubmit}>
					Skip
				</Button>

				<Box className="add-customer-form-actions-wrapper">
					<Button variant="outlined" color="error" sx={{ paddingX: 6 }} onClick={onCancel}>
						Back
					</Button>

					<Button disableElevation variant="contained" color="primary" onClick={onSubmit}>
						Save and Next
					</Button>
				</Box>
			</Box>

			<CustomerUserDetailsDialog
				open={showUserDetailsDialog}
				userDetails={selectedUserDetails}
				societyDetails={societyDetails}
				onClose={handleCloseUserDetailsDialog}
			/>
		</Box>
	);
};

export default UserDetails;
