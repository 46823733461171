import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import VideocamIcon from "@mui/icons-material/Videocam";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import { IDeviceRowDetails } from ".";
import { sortBy } from "lodash";
import { downloadImageFromUrl } from "../../utils/commonUtils";
import { BASE_API_URL } from "../../utils/apiUtils";

interface IConnectedCamerasDialogProps {
	open: boolean;
	deviceDetails: IDeviceRowDetails | null;
	onClose: () => void;
}

interface IFullscreenImageDetails {
	url: string;
	alt: string;
}

const ConnectedCamerasDialog: FC<IConnectedCamerasDialogProps> = (props) => {
	const { open, deviceDetails, onClose } = props;

	const [fullscreenImageDetails, setFullscreenImageDetails] = useState<IFullscreenImageDetails | null>(null);

	function handleShowFullscreenImage(imageUrl: string, cameraName: string) {
		setFullscreenImageDetails({
			url: imageUrl,
			alt: cameraName
		});
	}

	function handleHideFullscreenImage() {
		setFullscreenImageDetails(null);
	}

	function handleDownloadCameraImage(imageUrl: string, fileName: string) {
		downloadImageFromUrl(imageUrl, fileName);
	}

	const keyPressEventListener = useCallback(
		(event: KeyboardEvent) => {
			if (open) {
				if (event.key === "Escape") {
					if (fullscreenImageDetails) {
						handleHideFullscreenImage();
					}
				}
			}
		},
		[fullscreenImageDetails, open]
	);

	function isDialogSmaller() {
		// if (getCameraFramesLoading || getCameraFramesFetching) return true;
		if (!deviceDetails) return true;
		if (Object.keys(deviceDetails.camera_names).length <= 1) return true;
		return false;
	}

	useEffect(() => {
		document.addEventListener("keydown", keyPressEventListener, false);
		return () => {
			document.removeEventListener("keydown", keyPressEventListener, false);
		};
	}, [keyPressEventListener]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth={isDialogSmaller() ? "sm" : "lg"}
			disableEscapeKeyDown={!!fullscreenImageDetails}
		>
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Connected Camera
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box className="connected-cameras-dialog-content-wrapper">
					<Box className="device-details-header">
						<Box className="device-name-wrapper">
							<DeveloperBoardIcon />

							<Typography fontWeight={500}>{deviceDetails?.device_name ?? ""}</Typography>
						</Box>
					</Box>

					{!deviceDetails || Object.keys(deviceDetails.camera_names).length <= 0 ? (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem" }}>
							<Typography>No Cameras Found</Typography>
						</Box>
					) : (
						<Box className="cameras-list-wrapper" sx={{ gridTemplateColumns: isDialogSmaller() ? "1fr" : "1fr 1fr" }}>
							{sortBy(Object.keys(deviceDetails.camera_names)).map((cameraName) => {
								const cameraDetails = deviceDetails.camera_names[cameraName];

								return (
									<Box className="camera-details-card" key={cameraName}>
										<Box className="camera-details-card-header">
											<Box className="camera-name-wrapper">
												<VideocamIcon />

												<Typography fontSize={14} fontWeight={500}>
													{cameraName}
												</Typography>
											</Box>

											<Box className="camera-actions-wrapper">
												<Box
													className="camera-action-button"
													onClick={() =>
														handleShowFullscreenImage(cameraDetails.latest_camera_snapshot ?? "", cameraName)
													}
												>
													<FullscreenIcon />
												</Box>

												<Box
													className="camera-action-button"
													onClick={() =>
														handleDownloadCameraImage(cameraDetails.latest_camera_snapshot ?? "", cameraName)
													}
												>
													<DownloadIcon />
												</Box>
											</Box>
										</Box>

										<Box className="camera-sample-image">
											<img
												src={
													cameraDetails.latest_camera_snapshot
														? cameraDetails.latest_camera_snapshot.startsWith("http")
															? cameraDetails.latest_camera_snapshot
															: `${BASE_API_URL.replace("/api/v1", "")}${cameraDetails.latest_camera_snapshot}`
														: ""
												}
												alt={cameraName}
											/>
										</Box>
									</Box>
								);
							})}
						</Box>
					)}
				</Box>
			</DialogContent>

			{fullscreenImageDetails ? (
				<Box className="fullscreen-evidence-image-section" onClick={handleHideFullscreenImage}>
					<Box className="fullscreen-evidence-image-wrapper" onClick={(event) => event.stopPropagation()}>
						<img
							src={fullscreenImageDetails.url}
							alt={fullscreenImageDetails.alt}
							style={{ height: "100%", width: "100%", objectFit: "contain" }}
						/>
					</Box>

					<IconButton
						disableRipple
						sx={{ position: "absolute", top: "0", right: "0" }}
						onClick={handleHideFullscreenImage}
					>
						<FullscreenExitIcon sx={{ color: "white" }} fontSize="large" />
					</IconButton>
				</Box>
			) : null}
		</Dialog>
	);
};

export default ConnectedCamerasDialog;
