import { forwardRef } from "react";
import { createTheme } from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>(
	(props, ref) => {
		const { href, ...other } = props;
		return <RouterLink ref={ref} to={href} {...other} />;
	}
);

export const theme = createTheme({
	palette: {
		primary: {
			main: "#675AC1",
			light: "#F4F3FF",
			dark: "#403685"
		},
		secondary: {
			main: "#112c34"
		},
		success: {
			main: "#43A047",
			contrastText: "white",
			light: "#C8E6C9"
		},
		error: {
			main: "#F44336",
			light: "#FEEBEE"
		},
		warning: {
			main: "#FFB300",
			contrastText: "white",
			light: "#FFECB3"
		},
		info: {
			main: "#388DFF",
			contrastText: "white",
			light: "#E0F0FF",
			dark: "#1F53AF"
		},
		text: {
			primary: "#3D3D3D"
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 576,
			md: 768,
			lg: 992,
			xl: 1200
		}
	},
	typography: {
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
	},
	components: {
		MuiLink: {
			defaultProps: {
				component: LinkBehavior
			} as LinkProps
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior
			}
		}
	}
});
