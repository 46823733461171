import {
	AppBar,
	Avatar,
	Box,
	Button,
	CircularProgress,
	Collapse,
	CssBaseline,
	Dialog,
	DialogContent,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography
} from "@mui/material";
import React, { FC, Fragment, MouseEvent, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

import "./pageLayout.css";
import { ReactComponent as GGConnectLogo } from "../../assets/logos/gg-connect-logo.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { clearAuthState, clearUnauthState, logoutUser } from "../../redux/reducers/auth.reducer";
import authServices from "../../redux/services/auth.services";

interface ISidebarItem {
	text: string;
	icon: React.ReactNode;
	path: string;
	children?: Omit<ISidebarItem, "children">[];
}

const drawerWidth = 280;

const sidebarItemsList: ISidebarItem[] = [
	{
		text: "Customers",
		icon: <ApartmentIcon color="inherit" />,
		path: "/customers"
	},
	{
		text: "Devices",
		icon: <DeveloperBoardIcon color="inherit" />,
		path: "/devices"
	},
	{
		text: "Users",
		icon: <SupervisedUserCircleIcon color="inherit" />,
		path: "/users"
	},
	{
		text: "System Integrators",
		icon: <SettingsIcon color="inherit" />,
		path: "/system-integrators"
	},
	{
		text: "Status Monitor",
		icon: <MonitorHeartIcon color="inherit" />,
		path: "/status-monitor"
	}
];

const PageLayout: FC = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const authState = useAppSelector((state) => state.auth);

	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);
	const [expandedSidebarItem, setExpandedSidebarItem] = useState<string>("");

	const [profileMenuAnchorElement, setProfileMenuAnchorElement] = useState<HTMLDivElement | null>(null);
	const showProfileMenu = Boolean(profileMenuAnchorElement);

	function handleOpenMobileDrawer() {
		setMobileOpen(true);
	}

	function handleCloseMobileDrawer() {
		setMobileOpen(false);
	}

	function handleExpandSidebarDropdown(path: string) {
		setExpandedSidebarItem(path);
		handleCloseMobileDrawer();
	}

	function handleOpenProfileMenu(event: MouseEvent<HTMLDivElement>) {
		setProfileMenuAnchorElement(event.currentTarget);
	}

	function handleCloseProfileMenu() {
		setProfileMenuAnchorElement(null);
	}

	function handleLogout() {
		dispatch(logoutUser());
	}

	function handleLoginAgainClick() {
		dispatch(clearUnauthState());
	}

	function checkLoginToken() {
		authServices
			.handleCheckLoginToken()
			.then(() => {
				setShowLoader(false);
			})
			.catch((error) => {
				if (Number(error) === 401) dispatch(clearAuthState());
			});
	}

	useEffect(() => {
		if (!authState.user.token) {
			navigate("/");
		}
	}, [authState.user.token, navigate]);

	useEffect(() => {
		checkLoginToken();

		const activeScreen = sidebarItemsList.find((item) => location.pathname.startsWith(item.path));
		if (activeScreen && activeScreen.children) {
			setExpandedSidebarItem(activeScreen.path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const drawer = (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
			<Box className="mobile-drawer-close-icon">
				<IconButton sx={{ color: "#616161" }} size="small" onClick={handleCloseMobileDrawer}>
					<CloseIcon color="inherit" />
				</IconButton>
			</Box>

			<Toolbar classes={{ root: "page-layout-toolbar" }}>
				<GGConnectLogo width="85%" />
			</Toolbar>

			<Divider sx={{ borderColor: "#C5C1EA33", width: "80%" }} />

			<List sx={{ paddingX: "0.75rem", width: "100%" }}>
				{sidebarItemsList.map((item, index) => (
					<Fragment key={index}>
						<ListItem
							key={index}
							disablePadding
							classes={{
								root: `sidebar-list-item ${location.pathname.startsWith(item.path) ? "active" : ""}`
							}}
							sx={{ color: location.pathname.startsWith(item.path) ? "white" : "#616161" }}
						>
							<ListItemButton href={item.path} onClick={() => handleExpandSidebarDropdown(item.path)}>
								<ListItemIcon sx={{ color: location.pathname.startsWith(item.path) ? "white" : "#616161" }}>
									{item.icon}
								</ListItemIcon>
								<ListItemText primary={item.text} />

								{item.children ? (
									expandedSidebarItem === item.path ? (
										<ArrowDropUpIcon sx={{ color: "white" }} />
									) : (
										<ArrowDropDownIcon sx={{ color: "#616161" }} />
									)
								) : null}
							</ListItemButton>
						</ListItem>

						{item.children ? (
							<Collapse in={expandedSidebarItem === item.path} timeout="auto" unmountOnExit>
								<List
									component="div"
									disablePadding
									sx={{
										paddingLeft: "2.5rem",
										paddingY: "0.25rem",
										backgroundColor: "#F8F8FF",
										paddingRight: "0.25rem",
										borderBottomLeftRadius: "7px",
										borderBottomRightRadius: "7px"
									}}
								>
									{item.children.map((childItem) => (
										<ListItem
											key={childItem.path}
											disablePadding
											classes={{
												root: `sidebar-list-item child ${
													location.pathname.startsWith(`${item.path}${childItem.path}`) ? "active" : ""
												}`
											}}
											sx={{
												color: location.pathname.startsWith(`${item.path}${childItem.path}`) ? "white" : "#616161"
											}}
										>
											<ListItemButton
												sx={{ pl: 2 }}
												href={`${item.path}${childItem.path}`}
												onClick={handleCloseMobileDrawer}
											>
												<ListItemIcon
													sx={{
														color: location.pathname.startsWith(`${item.path}${childItem.path}`) ? "white" : "#616161",
														minWidth: "40px"
													}}
												>
													{childItem.icon}
												</ListItemIcon>
												<ListItemText primary={childItem.text} primaryTypographyProps={{ fontSize: "inherit" }} />
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Collapse>
						) : null}
					</Fragment>
				))}
			</List>
		</Box>
	);

	return (
		<>
			{showLoader ? (
				<Box sx={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			) : (
				<Box sx={{ display: "flex" }}>
					<CssBaseline />

					<Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}>
						<Drawer
							variant="temporary"
							open={mobileOpen}
							onClose={handleCloseMobileDrawer}
							ModalProps={{ keepMounted: true }}
							sx={{
								display: { xs: "block", lg: "none" },
								"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, border: "none" }
							}}
							PaperProps={{
								sx: { backgroundColor: "#FEFEFE" }
							}}
						>
							{drawer}
						</Drawer>

						<Drawer
							variant="permanent"
							sx={{
								display: { xs: "none", lg: "block" },
								"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, border: "none" }
							}}
							PaperProps={{
								sx: { backgroundColor: "#FEFEFE" }
							}}
							open
						>
							{drawer}
						</Drawer>
					</Box>

					<Box className="screen-content-wrapper" sx={{ width: { xs: "100%", lg: `calc(100vw - ${drawerWidth}px)` } }}>
						<AppBar
							sx={{
								backgroundColor: "#FEFEFE",
								padding: "1rem 0.5rem 1rem 0"
								// borderBottom: "1px solid #f2f1fb"
							}}
							position="relative"
							elevation={0}
						>
							<Box className="appbar-content-wrapper">
								<IconButton
									sx={{ color: "#616161", display: { xs: "block", lg: "none" } }}
									size="small"
									onClick={handleOpenMobileDrawer}
								>
									<GridMenuIcon color="inherit" />
								</IconButton>

								<Box className="header-actions-wrapper">
									<Box
										component="div"
										className="header-avatar-wrapper"
										id="profile-button"
										aria-controls={showProfileMenu ? "profile-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={showProfileMenu ? "true" : undefined}
										onClick={handleOpenProfileMenu}
									>
										<Typography variant="body2" fontWeight={500} textTransform="capitalize">
											{authState.user.full_name ?? ""}
										</Typography>

										<Avatar
											sx={{ width: "24px", height: "24px", fontSize: "inherit", bgcolor: "var(--color-primary-main)" }}
										>
											{(authState.user.full_name ?? "").slice(0, 1).toUpperCase()}
										</Avatar>
									</Box>

									<Menu
										id="profile-menu"
										anchorEl={profileMenuAnchorElement}
										open={showProfileMenu}
										onClose={handleCloseProfileMenu}
										MenuListProps={{ "aria-labelledby": "profile-button" }}
										disableAutoFocusItem
									>
										{/* <MenuItem disableRipple>
                  <ListItemIcon>
                    <KeyIcon fontSize="small" />
                  </ListItemIcon>

                  <ListItemText>
                    <Typography variant="body2">Change Password</Typography>
                  </ListItemText>
                </MenuItem> */}

										<MenuItem disableRipple onClick={handleLogout}>
											<ListItemIcon>
												<LogoutIcon fontSize="small" />
											</ListItemIcon>

											<ListItemText>
												<Typography variant="body2">Logout</Typography>
											</ListItemText>
										</MenuItem>
									</Menu>
								</Box>
							</Box>
						</AppBar>

						<Box component="main" className="screen-main-component">
							<Box className="screen-outlet-wrapper">
								<Outlet />
							</Box>
						</Box>
					</Box>

					<Dialog open={authState.unauthorizedError} maxWidth="xs" fullWidth>
						<DialogContent>
							<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
								<Typography variant="h6" textAlign={"center"}>
									Your login session has expired
								</Typography>

								<Button variant="contained" sx={{ marginTop: 2 }} onClick={handleLoginAgainClick}>
									Login Again
								</Button>
							</Box>
						</DialogContent>
					</Dialog>
				</Box>
			)}
		</>
	);
};

export default PageLayout;
