import {
	Autocomplete,
	Box,
	Button,
	ClickAwayListener,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	TextField,
	Typography
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import "./devices.css";
import { DataGrid, GridColDef, GridPagination, GridPaginationModel, GridRenderCellParams } from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../../utils/constants";
import { useGetDevicesMetricsQuery, useGetDevicesQuery } from "../../redux/reducers/devices.reducer";
import { DeviceStatusType, IDeviceCameraDetails, IGetDevicesRequestData } from "../../types";
import TablePagination from "../../components/TablePagination";
import DeviceDetailsDialog from "./DeviceDetailsDialog";
import ConnectedCamerasDialog from "./ConnectedCamerasDialog";
import { useGetAllCustomersQuery } from "../../redux/reducers/settings.reducer";
import { useLocation } from "react-router-dom";
import { CustomAutocompleteInput, CustomAutocompletePopper } from "../../components/CustomAutocomplete";

export interface IDeviceRowDetails {
	id: number;
	customer_id: number;
	customer_name: string;
	device_name: string;
	global_ip: string;
	status: DeviceStatusType;
	cameras_count: number;
	serial_number: string;
	local_ip: string;
	barrier_type: number;
	camera_names: IDeviceCameraDetails;
	release_version: string;
}

const DevicesListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const Devices: FC = () => {
	const location = useLocation();

	// RENDERER REFS
	// STATUS DROPDOWN
	const statusDropdownAnchorRef = useRef<HTMLDivElement>(null);

	// CUSTOMER DROPDOWN
	const customerDropdownAnchorRef = useRef<HTMLDivElement>(null);

	const [searchInput, setSearchInput] = useState<string>("");
	const [filters, setFilters] = useState<IGetDevicesRequestData>({ page: 1 });

	const [showDeviceDetailsDialog, setShowDeviceDetailsDialog] = useState<boolean>(false);
	const [showConnectedCamerasDetailsDialog, setShowConnectedCamerasDetailsDialog] = useState<boolean>(false);
	const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<IDeviceRowDetails | null>(null);

	// RENDERER STATES
	// STATUS DROPDOWN
	const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);

	// CUSTOMER DROPDOWN
	const [customerDropdownOpen, setCustomerDropdownOpen] = useState(false);

	// APIS
	// GET ALL CUSTOMERS
	const { data: getAllCustomersResponse } = useGetAllCustomersQuery();

	// GET DEVICES
	const {
		data: getDevicesResponse,
		isLoading: getDevicesLoading,
		isFetching: getDevicesFetching,
		refetch: refetchDevices
	} = useGetDevicesQuery(filters);

	// GET DEVICES METRICS
	const { data: getDevicesMetricsResponse, refetch: refetchDevicesMetrics } = useGetDevicesMetricsQuery();

	function refetchAllData() {
		refetchDevices();
		refetchDevicesMetrics();
	}

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleSearchDevice(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (searchInput) updatedFilters.search = searchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearSearchInput() {
		setSearchInput("");

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handleChangeSelectedStatus(updatedValue: DeviceStatusType | null) {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			if (updatedValue) updatedFilters.device_status = updatedValue;
			else delete updatedFilters.device_status;

			return updatedFilters;
		});

		handleCloseStatusDropdown();
	}

	function handlePageChange(updatedModel: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: updatedModel.page + 1
		}));
	}

	function getDevicesList(): IDeviceRowDetails[] {
		if (!getDevicesResponse) return [];

		return getDevicesResponse.results.map((deviceItem) => ({
			id: deviceItem.id,
			customer_id: deviceItem.customer_id,
			customer_name: deviceItem.customer_name,
			device_name: deviceItem.device_name,
			global_ip: deviceItem.global_ipv4_address,
			status: deviceItem.device_status ?? "Offline",
			cameras_count: deviceItem.cameras,
			serial_number: deviceItem.device_uuid,
			local_ip: deviceItem.local_ipv4_address,
			barrier_type: deviceItem.barrier_config,
			camera_names: deviceItem.camera_names,
			release_version: deviceItem.release_version
		}));
	}

	function handleOpenDeviceDetailsDialog(deviceDetails: IDeviceRowDetails | null) {
		setSelectedDeviceDetails(deviceDetails);
		setShowDeviceDetailsDialog(true);
	}

	function handleCloseDeviceDetailsDialog() {
		setShowDeviceDetailsDialog(false);
		setSelectedDeviceDetails(null);
		refetchAllData();
	}

	function handleOpenConnectedCamerasDetailsDialog(deviceDetails: IDeviceRowDetails) {
		setSelectedDeviceDetails(deviceDetails);
		setShowConnectedCamerasDetailsDialog(true);
	}

	function handleCloseConnectedCamerasDetailsDialog() {
		setShowConnectedCamerasDetailsDialog(false);
		setSelectedDeviceDetails(null);
	}

	function getCustomerName() {
		if (getAllCustomersResponse && typeof filters.customer_id === "number") {
			const selectedCustomer = getAllCustomersResponse.find((item) => item.id === filters.customer_id);

			if (selectedCustomer) return selectedCustomer.name;
		}

		return "Customer Name";
	}

	// RENDERER HELPER FUNCTIONS
	// STATUS DROPDOWN
	function handleOpenStatusDropdown() {
		setStatusDropdownOpen(true);
	}

	function handleCloseStatusDropdown() {
		setStatusDropdownOpen(false);
	}

	// CUSTOMER DROPDOWN
	function handleOpenCustomerDropdown() {
		setCustomerDropdownOpen(true);
	}

	function handleCloseCustomerDropdown() {
		setCustomerDropdownOpen(false);
	}

	function handleChangeSelectedCustomer(updatedValue?: number | null) {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			if (updatedValue && typeof updatedValue === "number") updatedFilters.customer_id = updatedValue;
			else delete updatedFilters.customer_id;

			return updatedFilters;
		});
	}

	useEffect(() => {
		if (location.state && typeof location.state.customer_id === "number") {
			handleChangeSelectedCustomer(location.state.customer_id);
		}
	}, [location.state]);

	// RENDERER FUNCTIONS
	// STATUS DROPDOWN
	function renderStatusDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					id="status-dropdown"
					aria-controls="status-dropdown-menu"
					aria-haspopup="true"
					aria-expanded={statusDropdownOpen}
					onClick={handleOpenStatusDropdown}
					ref={statusDropdownAnchorRef}
				>
					<Typography variant="subtitle2">{filters.device_status ? filters.device_status : "Status"}</Typography>

					{statusDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="status-dropdown-menu"
					anchorEl={statusDropdownAnchorRef.current}
					open={statusDropdownOpen}
					onClose={handleCloseStatusDropdown}
					MenuListProps={{ "aria-labelledby": "status-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedStatus(null)} sx={{ minWidth: 100 }}>
						<em>All</em>
					</MenuItem>

					<MenuItem onClick={() => handleChangeSelectedStatus("Online")}>Online</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedStatus("Offline")}>Offline</MenuItem>
				</Menu>
			</Box>
		);
	}

	// CUSTOMER DROPDOWN
	function renderCustomerDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					ref={customerDropdownAnchorRef}
					onClick={handleOpenCustomerDropdown}
					aria-describedby="customer-dropdown-menu"
				>
					<Typography variant="subtitle2">{getCustomerName()}</Typography>
					{customerDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<CustomAutocompletePopper
					id="customer-dropdown-menu"
					open={customerDropdownOpen}
					anchorEl={customerDropdownAnchorRef.current}
					placement="bottom-start"
				>
					<ClickAwayListener onClickAway={handleCloseCustomerDropdown}>
						<Autocomplete
							open
							disableCloseOnSelect
							onClose={(_event, reason) => {
								if (reason === "escape") {
									handleCloseCustomerDropdown();
								}
							}}
							value={
								getAllCustomersResponse ? getAllCustomersResponse.find((item) => item.id === filters.customer_id) : null
							}
							onChange={(_event, updatedValue) => handleChangeSelectedCustomer(updatedValue?.id)}
							options={getAllCustomersResponse ?? []}
							getOptionKey={(item) => item.id}
							getOptionLabel={(item) => item.name}
							// renderInput={(params) => (
							// 	<TextField
							// 		{...params}
							// 		fullWidth
							// 		variant="outlined"
							// 		size="small"
							// 		placeholder="Search"
							// 		// InputProps={{ sx: { padding: "1rem" } }}
							// 	/>
							// )}
							componentsProps={{
								paper: { sx: { borderRadius: 0, borderBottomRightRadius: 6, borderBottomLeftRadius: 6 } }
							}}
							renderInput={(params) => (
								<CustomAutocompleteInput
									ref={params.InputProps.ref}
									inputProps={params.inputProps}
									autoFocus
									placeholder="Search"
									fullWidth
								/>
							)}
						/>
					</ClickAwayListener>
				</CustomAutocompletePopper>
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{
			field: "customer_name",
			headerName: "Customer Name",
			flex: 2,
			minWidth: 200,
			sortable: false,
			renderHeader: renderCustomerDropdown
		},
		{
			field: "device_name",
			headerName: "Device Name",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "global_ip",
			headerName: "Global IP",
			flex: 1,
			minWidth: 120,
			sortable: false
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 120,
			sortable: false,
			renderHeader: renderStatusDropdown,
			renderCell: (params: GridRenderCellParams<IDeviceRowDetails, DeviceStatusType>) => (
				<Box className={`device-status ${params.value}`}>
					<FiberManualRecordIcon sx={{ fontSize: "18px" }} />

					<Typography variant="body2" color="#000000DE">
						{params.value}
					</Typography>
				</Box>
			)
		},
		{
			field: "cameras_count",
			headerName: "Cameras",
			flex: 1,
			minWidth: 80,
			sortable: false,
			headerAlign: "center",
			align: "center"
		},
		{
			field: "check_cameras",
			headerName: "",
			width: 150,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IDeviceRowDetails>) => (
				<Box className="check-cameras-cell">
					<Button
						variant="text"
						size="small"
						color="info"
						sx={{ textTransform: "capitalize" }}
						endIcon={<KeyboardArrowRightIcon />}
						// disabled={params.row.status === "Offline"}
						onClick={() => handleOpenConnectedCamerasDetailsDialog(params.row)}
					>
						Check Cameras
					</Button>
				</Box>
			)
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IDeviceRowDetails>) => (
				<Box className="devices-table-row-actions">
					<IconButton color="inherit" size="small" onClick={() => handleOpenDeviceDetailsDialog(params.row)}>
						<InfoIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="screen-wrapper">
			<Box className="screen-stats-wrapper">
				<Box className="total-count-wrapper">
					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper">
							<DeveloperBoardIcon sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getDevicesMetricsResponse?.total_device ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Devices
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box className="category-count-wrapper">
					<Box className="count-wrapper divider-before">
						<Box className="count-icon-wrapper success">
							<FiberManualRecordIcon sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getDevicesMetricsResponse?.online_device ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Online Devices
							</Typography>
						</Box>
					</Box>

					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper error">
							<FiberManualRecordIcon color="error" sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getDevicesMetricsResponse?.offline_device ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Offline Devices
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="table-actions-wrapper">
				<Box component="form" noValidate onSubmit={handleSearchDevice}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={searchInput}
						onChange={handleChangeSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "table-search-input" },
							endAdornment: (
								<InputAdornment position="end">
									{filters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={() => handleOpenDeviceDetailsDialog(null)}
				>
					Add
				</Button>
			</Box>

			<Box className="table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getDevicesList()}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: DevicesListPagination }}
					hideFooter={(getDevicesResponse?.count ?? 0) <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					paginationMode="server"
					paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					onPaginationModelChange={handlePageChange}
					rowCount={getDevicesResponse?.count ?? 0}
					loading={getDevicesLoading || getDevicesFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root"
					}}
				/>
			</Box>

			<DeviceDetailsDialog
				open={showDeviceDetailsDialog}
				deviceDetails={selectedDeviceDetails}
				onClose={handleCloseDeviceDetailsDialog}
			/>

			<ConnectedCamerasDialog
				open={showConnectedCamerasDetailsDialog}
				deviceDetails={selectedDeviceDetails}
				onClose={handleCloseConnectedCamerasDetailsDialog}
			/>
		</Box>
	);
};

export default Devices;
