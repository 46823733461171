import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { ICustomerDeviceRowDetails } from "../DeviceRegistration";
import { ISocietyConfigDetails } from "../../../../types";
import {
	useAddSocietyDeviceMutation,
	useEditSocietyDeviceMutation
} from "../../../../redux/reducers/customers.reducer";
import { LoadingButton } from "@mui/lab";

interface ICustomerDeviceFormFields {
	device_name: string;
	serial_number: string;
	barrier_config: number;
	release_version: string;
}

interface ICustomerDeviceDetailsDialogProps {
	open: boolean;
	deviceDetails: ICustomerDeviceRowDetails | null;
	societyDetails: ISocietyConfigDetails | null;
	onClose: () => void;
}

const CustomerDeviceDetailsDialog: FC<ICustomerDeviceDetailsDialogProps> = (props) => {
	const { open, deviceDetails, societyDetails, onClose } = props;

	// APIS
	const [
		addSocietyDevice,
		{
			isSuccess: addSocietyDeviceSuccess,
			isLoading: addSocietyDeviceLoading,
			error: addSocietyDeviceError,
			reset: resetAddSocietyDeviceState
		}
	] = useAddSocietyDeviceMutation();

	const [
		editSocietyDevice,
		{
			isSuccess: editSocietyDeviceSuccess,
			isLoading: editSocietyDeviceLoading,
			error: editSocietyDeviceError,
			reset: resetEditSocietyDeviceState
		}
	] = useEditSocietyDeviceMutation();

	const {
		watch,
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<ICustomerDeviceFormFields>();

	const barrierConfig = watch("barrier_config");

	function handleSubmitForm(formData: ICustomerDeviceFormFields) {
		if (deviceDetails) {
			editSocietyDevice({
				id: deviceDetails.id,
				data: {
					device_uuid: formData.serial_number,
					society: societyDetails?.id ?? 0,
					device_name: formData.device_name,
					barrier_config: formData.barrier_config,
					release_version: formData.release_version
				}
			});
		} else {
			addSocietyDevice({
				device_uuid: formData.serial_number,
				society: societyDetails?.id ?? 0,
				device_name: formData.device_name,
				barrier_config: formData.barrier_config,
				release_version: formData.release_version
			});
		}
	}

	useEffect(() => {
		if (open) {
			if (deviceDetails) {
				reset({
					device_name: deviceDetails.device_name,
					serial_number: deviceDetails.serial_no,
					barrier_config: deviceDetails.barrier_config,
					release_version: deviceDetails.release_version
				});
			} else {
				reset({
					device_name: "",
					serial_number: "",
					barrier_config: 0,
					release_version: ""
				});
			}
		} else {
			resetAddSocietyDeviceState();
			resetEditSocietyDeviceState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, deviceDetails, reset]);

	useEffect(() => {
		if (addSocietyDeviceSuccess) {
			onClose();
		}
	}, [addSocietyDeviceSuccess, onClose]);

	useEffect(() => {
		if (editSocietyDeviceSuccess) {
			onClose();
		}
	}, [editSocietyDeviceSuccess, onClose]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				{deviceDetails ? "Device Details" : "Add New Device"}

				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="device-name-input"
								error={!!errors.device_name}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Device Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="device-name-input"
								placeholder="Enter the Device Name"
								error={!!errors.device_name}
								helperText={
									errors.device_name && errors.device_name.message ? errors.device_name.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("device_name", { required: "Device name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="serial-number-input"
								error={!!errors.serial_number}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Serial Number
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="serial-number-input"
								placeholder="Enter the Serial Number"
								error={!!errors.serial_number}
								helperText={
									errors.serial_number && errors.serial_number.message ? errors.serial_number.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("serial_number", { required: "Serial number is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="release-version-input"
								error={!!errors.release_version}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Release Version
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="release-version-input"
								placeholder="Enter the Release Version"
								error={!!errors.release_version}
								helperText={
									errors.release_version && errors.release_version.message
										? errors.release_version.message.toString()
										: ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("release_version", { required: "Release version is required" })}
							/>
						</Grid>

						<Grid item xs={12}>
							<InputLabel
								required
								htmlFor="barrier-config-input"
								error={!!errors.barrier_config}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Barrier Configuration
							</InputLabel>

							<FormControl fullWidth>
								<RadioGroup row sx={{ gap: "2rem" }}>
									<FormControlLabel
										value={0}
										control={<Radio />}
										label="Single (Entry)"
										checked={Number(barrierConfig) === 0}
										{...register("barrier_config", { required: true })}
									/>

									<FormControlLabel
										value={1}
										control={<Radio />}
										label="Single (Exit)"
										checked={Number(barrierConfig) === 1}
										{...register("barrier_config", { required: true })}
									/>

									<FormControlLabel
										value={2}
										control={<Radio />}
										label="Double (Entry + Exit)"
										checked={Number(barrierConfig) === 2}
										{...register("barrier_config", { required: true })}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>

						{addSocietyDeviceError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in addSocietyDeviceError && addSocietyDeviceError.data
										? `${String(addSocietyDeviceError.data).charAt(0).toUpperCase()}${String(
												addSocietyDeviceError.data
										  ).slice(1)}`
										: "Failed to add device. Please try again later"}
								</Typography>
							</Grid>
						) : null}

						{editSocietyDeviceError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in editSocietyDeviceError && editSocietyDeviceError.data
										? `${String(editSocietyDeviceError.data).charAt(0).toUpperCase()}${String(
												editSocietyDeviceError.data
										  ).slice(1)}`
										: "Failed to edit device. Please try again later"}
								</Typography>
							</Grid>
						) : null}

						<Grid item xs={12}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<LoadingButton
									disableElevation
									variant="contained"
									color="success"
									sx={{ paddingX: 4 }}
									type="submit"
									loading={deviceDetails ? editSocietyDeviceLoading : addSocietyDeviceLoading}
								>
									Save
								</LoadingButton>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default CustomerDeviceDetailsDialog;
