import {
	Autocomplete,
	Box,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";

import { IUserRowDetails } from "..";
import { IGetAllCustomersResponseDetails, UserStatusType } from "../../../types";
import { useGetAllCustomersQuery } from "../../../redux/reducers/settings.reducer";
import NumberInput from "../../../components/NumberInput";
import { CUSTOMER_ROLES_LIST } from "../../../utils/constants";
import { useCreateUserMutation, useEditUserMutation } from "../../../redux/reducers/users.reducer";

interface IUserDetailsDialogProps {
	open: boolean;
	userDetails: IUserRowDetails | null;
	onClose: () => void;
}

interface IUserFormFields {
	first_name: string;
	last_name: string;
	login_id: string;
	customer: number;
	contact_number: string;
	role: number;
	designation: string;
	user_status: UserStatusType;
	password: string;
	email: string;
	address: string;
	allow_secure_login: boolean;
}

const UserDetailsDialog: FC<IUserDetailsDialogProps> = (props) => {
	const { open, userDetails, onClose } = props;

	// APIS
	const { data: getAllCustomersResponse } = useGetAllCustomersQuery();

	const [
		createUser,
		{ isLoading: createUserLoading, isSuccess: createUserSuccess, error: createUserError, reset: resetCreateUserState }
	] = useCreateUserMutation();

	const [
		editUser,
		{ isLoading: editUserLoading, isSuccess: editUserSuccess, error: editUserError, reset: resetEditUserState }
	] = useEditUserMutation();

	const {
		reset,
		watch,
		control,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IUserFormFields>();

	const selectedCustomerId = watch("customer");
	const selectedCustomer = useMemo<IGetAllCustomersResponseDetails | null>(() => {
		if (!getAllCustomersResponse) return null;
		const customerDetails = getAllCustomersResponse.find((item) => item.id === selectedCustomerId);
		return customerDetails ?? null;
	}, [getAllCustomersResponse, selectedCustomerId]);

	function handleSubmitForm(formData: IUserFormFields) {
		if (userDetails) {
			editUser({
				id: userDetails.id,
				data: {
					username: formData.login_id,
					first_name: formData.first_name,
					last_name: formData.last_name,
					address: formData.address,
					phone_number: formData.contact_number,
					role: formData.role,
					society: [formData.customer],
					designation: formData.designation,
					email: formData.email,
					is_active: formData.user_status === "Active",
					allow_secure_login: formData.allow_secure_login
				}
			});
		} else {
			createUser({
				username: formData.login_id,
				first_name: formData.first_name,
				last_name: formData.last_name,
				address: formData.address,
				phone_number: formData.contact_number,
				role: formData.role,
				society: [formData.customer],
				designation: formData.designation,
				password: formData.password,
				email: formData.email,
				allow_secure_login: formData.allow_secure_login
			});
		}
	}

	useEffect(() => {
		if (open) {
			if (userDetails) {
				reset({
					first_name: userDetails.first_name,
					last_name: userDetails.last_name,
					login_id: userDetails.login_id,
					customer: userDetails.customer_id,
					contact_number: userDetails.contact_number,
					role: userDetails.role_id,
					designation: userDetails.designation,
					user_status: userDetails.status,
					password: "",
					address: userDetails.address,
					email: userDetails.email,
					allow_secure_login: userDetails.allow_secure_login
				});
			} else {
				reset({
					first_name: "",
					last_name: "",
					login_id: "",
					customer: 0,
					contact_number: "",
					role: 0,
					designation: "",
					user_status: "Active",
					password: "",
					address: "",
					email: "",
					allow_secure_login: false
				});
			}
		} else {
			resetCreateUserState();
			resetEditUserState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, reset, userDetails]);

	useEffect(() => {
		if (createUserSuccess) {
			onClose();
		}
	}, [createUserSuccess, onClose]);

	useEffect(() => {
		if (editUserSuccess) {
			onClose();
		}
	}, [editUserSuccess, onClose]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				{userDetails ? "User Details" : "Add New User"}

				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="first-name-input"
								error={!!errors.first_name}
								classes={{ root: "user-form-input-label-root" }}
							>
								First Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="first-name-input"
								placeholder="Add first name of the person"
								error={!!errors.first_name}
								helperText={errors.first_name && errors.first_name.message ? errors.first_name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("first_name", { required: "First name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="last-name-input"
								error={!!errors.last_name}
								classes={{ root: "user-form-input-label-root" }}
							>
								Last Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="last-name-input"
								placeholder="Add last name of the person"
								error={!!errors.last_name}
								helperText={errors.last_name && errors.last_name.message ? errors.last_name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("last_name", { required: "Last name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="login-id-input"
								error={!!errors.login_id}
								classes={{ root: "user-form-input-label-root" }}
							>
								Login ID
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="login-id-input"
								placeholder="Add login ID for the person"
								error={!!errors.login_id}
								helperText={errors.login_id && errors.login_id.message ? errors.login_id.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("login_id", { required: "Login ID is required" })}
							/>
						</Grid>

						{!userDetails ? (
							<Grid item xs={12} md={6}>
								<InputLabel
									required
									htmlFor="password-input"
									error={!!errors.password}
									classes={{ root: "user-form-input-label-root" }}
								>
									Password
								</InputLabel>

								<TextField
									required
									fullWidth
									size="small"
									type="password"
									variant="filled"
									id="password-input"
									placeholder="Add password"
									error={!!errors.password}
									helperText={errors.password && errors.password.message ? errors.password.message.toString() : ""}
									InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
									{...register("password", { required: "Password is required" })}
								/>
							</Grid>
						) : null}

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="contact-number-input"
								error={!!errors.contact_number}
								classes={{ root: "user-form-input-label-root" }}
							>
								Contact Number
							</InputLabel>

							<NumberInput
								positiveOnly
								disableSigned
								disableDecimal
								isContactNumber
								required
								fullWidth
								size="small"
								variant="filled"
								id="contact-number-input"
								placeholder="Add contact number"
								error={!!errors.contact_number}
								helperText={
									errors.contact_number && errors.contact_number.message ? errors.contact_number.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("contact_number", { required: "Contact Number is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="email-input"
								error={!!errors.email}
								classes={{ root: "user-form-input-label-root" }}
							>
								Email
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="email-input"
								placeholder="Add email"
								error={!!errors.email}
								helperText={errors.email && errors.email.message ? errors.email.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("email", { required: "Email is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="address-input"
								error={!!errors.address}
								classes={{ root: "user-form-input-label-root" }}
							>
								Address
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="address-input"
								placeholder="Add address"
								error={!!errors.address}
								helperText={errors.address && errors.address.message ? errors.address.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("address", { required: "Address is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="customer-input"
								error={!!errors.customer}
								classes={{ root: "user-form-input-label-root" }}
							>
								Customer
							</InputLabel>

							<Controller
								control={control}
								name="customer"
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={getAllCustomersResponse ?? []}
										getOptionLabel={(option) => option.name}
										getOptionKey={(option) => option.id}
										value={getAllCustomersResponse?.find((item) => item.id === value) ?? null}
										onChange={(_, updatedValue) => onChange(updatedValue?.id ?? null)}
										id="customer-input"
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												hiddenLabel
												size="small"
												variant="filled"
												placeholder="Select the customer"
												error={!!errors.customer}
												helperText={
													errors.customer && errors.customer.message ? errors.customer.message.toString() : ""
												}
												InputProps={{
													...params.InputProps,
													hiddenLabel: true,
													disableUnderline: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="role-input"
								error={!!errors.role}
								classes={{ root: "user-form-input-label-root" }}
							>
								Role
							</InputLabel>

							<Controller
								name="role"
								control={control}
								rules={{ required: "Role is required" }}
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={CUSTOMER_ROLES_LIST}
										getOptionLabel={(option) => option.label}
										getOptionKey={(option) => option.key}
										value={CUSTOMER_ROLES_LIST.find((item) => item.key === value) ?? null}
										onChange={(_, updatedValue) => onChange(updatedValue?.key ?? null)}
										id="role-input"
										renderInput={(params) => (
											<TextField
												{...params}
												required
												fullWidth
												hiddenLabel
												size="small"
												variant="filled"
												placeholder="Enter the Role"
												error={!!errors.role}
												helperText={errors.role && errors.role.message ? errors.role.message.toString() : ""}
												InputProps={{
													...params.InputProps,
													hiddenLabel: true,
													disableUnderline: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="designation-input"
								error={!!errors.designation}
								classes={{ root: "user-form-input-label-root" }}
							>
								Designation
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="designation-input"
								placeholder="Add designation"
								error={!!errors.designation}
								helperText={
									errors.designation && errors.designation.message ? errors.designation.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("designation", { required: "Designation is required" })}
							/>
						</Grid>

						{userDetails ? (
							<Grid item xs={12} md={6}>
								<InputLabel
									required
									htmlFor="user-status-input"
									error={!!errors.user_status}
									classes={{ root: "user-form-input-label-root" }}
								>
									User Status
								</InputLabel>

								<Controller
									control={control}
									name="user_status"
									rules={{ required: "User Status is required" }}
									render={({ field: { value, onChange } }) => (
										<Select
											id="user-status-input"
											required
											fullWidth
											size="small"
											variant="filled"
											hiddenLabel
											error={!!errors.user_status}
											disableUnderline
											classes={{
												root: `filled-input-root ${
													value === "Active"
														? "success"
														: value === "Frequent"
														? "warning"
														: value === "Inactive"
														? "error"
														: ""
												}`
											}}
											value={value}
											onChange={(event) => onChange(event.target.value)}
											MenuProps={{}}
										>
											<MenuItem value="Active">Active</MenuItem>
											<MenuItem value="Inactive">Inactive</MenuItem>
										</Select>
									)}
								/>

								{errors.user_status && errors.user_status.message ? (
									<FormHelperText error>{errors.user_status.message.toString()}</FormHelperText>
								) : null}
							</Grid>
						) : null}

						{selectedCustomer && selectedCustomer.enable_secure_login ? (
							<Grid item xs={12}>
								<Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
									<Controller
										control={control}
										name="allow_secure_login"
										render={({ field: { value, onChange } }) => (
											<FormControl>
												<FormControlLabel
													label="Allow secure login"
													control={<Checkbox checked={value} onChange={(_event, checked) => onChange(checked)} />}
												/>
											</FormControl>
										)}
									/>
								</Box>
							</Grid>
						) : null}

						{createUserError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in createUserError && createUserError.data
										? `${String(createUserError.data).charAt(0).toUpperCase()}${String(createUserError.data).slice(1)}`
										: "Something went wrong"}
								</Typography>
							</Grid>
						) : null}

						{editUserError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in editUserError && editUserError.data
										? `${String(editUserError.data).charAt(0).toUpperCase()}${String(editUserError.data).slice(1)}`
										: "Something went wrong"}
								</Typography>
							</Grid>
						) : null}

						<Grid item xs={12}>
							{userDetails ? (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									{/* <LoadingButton variant="text" color="error" startIcon={<DeleteOutlineIcon />}>
										Delete User
									</LoadingButton> */}

									<LoadingButton
										disableElevation
										variant="contained"
										color="primary"
										startIcon={<SaveIcon />}
										loadingPosition="start"
										type="submit"
										loading={editUserLoading}
									>
										Save
									</LoadingButton>
								</Box>
							) : (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<LoadingButton
										disableElevation
										variant="contained"
										color="success"
										sx={{ paddingX: 4 }}
										loading={createUserLoading}
										type="submit"
									>
										Save
									</LoadingButton>
								</Box>
							)}
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default UserDetailsDialog;
