import axios from "axios";

import { ILoggedInUserDetails, ILoginUserRequestData } from "../../types";
import { BASE_API_URL, getAPIHeaderOptions, getDefaultParams } from "../../utils/apiUtils";

const handleLoginUser = (requestData: ILoginUserRequestData): Promise<ILoggedInUserDetails> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${BASE_API_URL}/auth/admin/login`, requestData, { headers: getAPIHeaderOptions() })
			.then((response) => resolve(response.data))
			.catch((error) => {
				let errorMessage = "Something went wrong";

				if (error && error.response && error.response.data) {
					const errorValue = Object.values(error.response.data)[0];
					if (errorValue) errorMessage = String(errorValue);
				}

				reject(errorMessage);
			});
	});
};

const handleLogoutUser = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${BASE_API_URL}/auth/logout`, {}, { headers: getAPIHeaderOptions(true) })
			.then(() => resolve())
			.catch((error) => reject(error?.response?.data?.detail ?? "Something went wrong"));
	});
};

const handleCheckLoginToken = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/analytics/ownertype`, { headers: getAPIHeaderOptions(true), params: getDefaultParams() })
			.then((response) => {
				if (response.status === 200) resolve();
				else reject(response.status);
			})
			.catch((error) => reject(error?.response?.status));
	});
};

const authServices = { handleLoginUser, handleLogoutUser, handleCheckLoginToken };

export default authServices;
