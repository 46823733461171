export const ROWS_PER_PAGE = 20;

// BUILDING CONFIG
export enum BUILDING_TYPES_E {
	BUILDING = 0,
	CUSTOM_ENTRIES = 1
}

export const CUSTOM_ENTRY_BUILDING_NAME = "Common Area";

export const TIME_FILTER_FORMAT = "YYYY-MM-DD HH:mm:ss";

export enum CUSTOMER_ROLE_E {
	ADMIN = 0,
	STAFF = 1,
	SYSTEM_INTEGRATOR = 2,
	TENANT = 3
	// DEVICE = 4
}

interface ICustomerRoleDetails {
	key: CUSTOMER_ROLE_E;
	label: string;
}

export const CUSTOMER_ROLES_LIST: ICustomerRoleDetails[] = [
	{ key: CUSTOMER_ROLE_E.ADMIN, label: "Admin" },
	{ key: CUSTOMER_ROLE_E.STAFF, label: "Staff" },
	{ key: CUSTOMER_ROLE_E.SYSTEM_INTEGRATOR, label: "System Integrator" },
	{ key: CUSTOMER_ROLE_E.TENANT, label: "Tenant" }
	// { key: CUSTOMER_ROLE_E.DEVICE, label: "Device" }
];
