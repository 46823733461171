import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { ISocietyContactDetails } from "../ContactDetails";
import NumberInput from "../../../../components/NumberInput";

interface ICustomerContactDetailsDialogProps {
	open: boolean;
	contactDetails: ISocietyContactDetails | null;
	contactsList: ISocietyContactDetails[];
	onClose: () => void;
	onSubmit: (contactDetails: ISocietyContactDetails) => void;
	onDelete: (id: number) => void;
}

const CustomerContactDetailsDialog: FC<ICustomerContactDetailsDialogProps> = (props) => {
	const { open, contactDetails, contactsList, onClose, onSubmit, onDelete } = props;

	const [formError, setFormError] = useState<string>("");

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<ISocietyContactDetails>();

	function handleSubmitForm(formData: ISocietyContactDetails) {
		if (formData.contact_number) {
			const existingContact = contactsList.find(
				(item) => String(item.contact_number) === String(formData.contact_number)
			);

			if (existingContact && existingContact.id !== contactDetails?.id) {
				setFormError("A contact with this number already exists");
				return;
			}
		}

		if (formData.email) {
			const existingContact = contactsList.find((item) => item.email === formData.email);

			if (existingContact && existingContact.id !== contactDetails?.id) {
				setFormError("A contact with this email already exists");
				return;
			}
		}

		if (contactDetails) {
			onSubmit({ ...formData, id: contactDetails.id });
		} else {
			onSubmit({ ...formData, id: -1 });
		}
	}

	useEffect(() => {
		if (open) {
			if (contactDetails) {
				reset({
					first_name: contactDetails.first_name,
					last_name: contactDetails.last_name,
					contact_number: contactDetails.contact_number,
					// role: contactDetails.role,
					designation: contactDetails.designation,
					email: contactDetails.email,
					is_subscribed: contactDetails.is_subscribed
				});
			} else {
				reset({
					first_name: "",
					last_name: "",
					contact_number: "",
					// role: "",
					designation: "",
					email: "",
					is_subscribed: false
				});
			}
		} else {
			setFormError("");
		}
	}, [contactDetails, open, reset]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Contact Details
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2} alignItems="flex-end">
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="first-name-input"
								error={!!errors.first_name}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								First Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="first-name-input"
								placeholder="Enter the First Name"
								error={!!errors.first_name}
								helperText={errors.first_name && errors.first_name.message ? errors.first_name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("first_name", { required: "First name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="last-name-input"
								error={!!errors.last_name}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Last Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="last-name-input"
								placeholder="Enter the Last Name"
								error={!!errors.last_name}
								helperText={errors.last_name && errors.last_name.message ? errors.last_name.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("last_name", { required: "Last name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="contact-number-input"
								error={!!errors.contact_number}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Contact Number
							</InputLabel>

							<NumberInput
								isContactNumber
								positiveOnly
								disableSigned
								disableDecimal
								required
								fullWidth
								size="small"
								variant="filled"
								id="contact-number-input"
								placeholder="Enter the Contact Number"
								error={!!errors.contact_number}
								helperText={
									errors.contact_number && errors.contact_number.message ? errors.contact_number.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("contact_number", { required: "Contact number is required" })}
							/>
						</Grid>

						{/* <Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="role-input"
								error={!!errors.role}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Role
							</InputLabel>

							<Controller
								name="role"
								control={control}
								defaultValue="-1"
								rules={{ required: "Flat number is required" }}
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={["Admin", "Staff", "System Integrator", "Tenant", "Device"]}
										// getOptionLabel={(option) => option.label}
										// getOptionKey={(option) => option.key}
										value={value}
										onChange={(_, updatedValue) => onChange(updatedValue)}
										id="role-input"
										renderInput={(params) => (
											<TextField
												{...params}
												required
												fullWidth
												hiddenLabel
												size="small"
												variant="filled"
												placeholder="Enter the Role"
												error={!!errors.role}
												helperText={errors.role && errors.role.message ? errors.role.message.toString() : ""}
												InputProps={{
													...params.InputProps,
													hiddenLabel: true,
													disableUnderline: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid> */}

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="designation-input"
								error={!!errors.designation}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Designation
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="designation-input"
								placeholder="Enter the Designation"
								error={!!errors.designation}
								helperText={
									errors.designation && errors.designation.message ? errors.designation.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("designation", { required: "Designation is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="email-input"
								error={!!errors.email}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Email
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="email-input"
								placeholder="Enter the Email"
								error={!!errors.email}
								helperText={errors.email && errors.email.message ? errors.email.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("email", { required: "Email is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* <InputLabel
								htmlFor="primary-user-input"
								error={!!errors.is_subscribed}
								classes={{ root: "user-form-input-label-root" }}
							>
								Subscribe to email updates
							</InputLabel> */}

							<Controller
								control={control}
								name="is_subscribed"
								render={({ field: { value, onChange } }) => (
									<FormControl fullWidth>
										<FormControlLabel
											control={<Checkbox checked={value} onChange={(_event, checked) => onChange(checked)} />}
											label="Subscribe to email updates"
										/>
									</FormControl>
								)}
							/>
						</Grid>

						{formError ? (
							<Grid item xs={12}>
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Typography variant="body2" color="var(--color-error-main)">
										{formError}
									</Typography>
								</Box>
							</Grid>
						) : null}

						<Grid item xs={12}>
							{contactDetails ? (
								<>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
										<Button
											disableElevation
											variant="text"
											color="error"
											startIcon={<DeleteOutlineIcon />}
											onClick={() => onDelete(contactDetails.id)}
											key={contactDetails.id}
										>
											Delete User
										</Button>

										<Button disableElevation variant="contained" color="primary" type="submit" startIcon={<SaveIcon />}>
											Save
										</Button>
									</Box>
								</>
							) : (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Button
										disableElevation
										variant="contained"
										color="success"
										sx={{ paddingX: 4 }}
										type="submit"
										key={-1}
									>
										Save
									</Button>
								</Box>
							)}
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default CustomerContactDetailsDialog;
