import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	styled,
	TextField,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography
} from "@mui/material";
import moment, { Moment } from "moment";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers-pro";
import React, { FC, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
	ICreateSocietyRequestData,
	IEditSocietyRequestData,
	ISocietyConfigDetails,
	ISocietySettingsResponseData
} from "../../../types";
import {
	useCreateSocietySettingsMutation,
	useEditSocietySettingsMutation
} from "../../../redux/reducers/customers.reducer";
import { useAppDispatch } from "../../../redux";
import NumberInput from "../../../components/NumberInput";
import { TIME_FILTER_FORMAT } from "../../../utils/constants";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import { useGetSystemIntegratorsQuery } from "../../../redux/reducers/systemIntegrators.reducer";
import { useCreateSocietyMutation, useEditSocietyConfigMutation } from "../../../redux/reducers/settings.reducer";

interface ISocietyDetailsFormFieldValues {
	society_name: string;
	society_uid: string;
	system_integrator: number;
	invoice_number: string;
	address_line_1: string;
	address_line_2: string;
	pincode: string;
	city: string;
	connect_start_date: Moment;
	connect_end_date: Moment;
	enable_secure_login: boolean;
}

interface ISystemIntegratorSelectionDetails {
	id: number;
	name: string;
}

interface IAddCustomerSocietyDetailsProps {
	societyDetails: ISocietyConfigDetails | null;
	societySettings: ISocietySettingsResponseData | null;
	onCancel: () => void;
	onSubmit: (createdSocietyDetails: ISocietyConfigDetails, societySettings?: ISocietySettingsResponseData) => void;
}

const CheckboxTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip arrow placement="top" classes={{ popper: className }} {...props} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#D7D1FF",
		color: theme.palette.text.primary,
		fontSize: 12,
		boxShadow: theme.shadows[2],
		fontWeight: 400,
		textAlign: "center",
		maxWidth: 200
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: "#D7D1FF"
	}
}));

const SocietyDetails: FC<IAddCustomerSocietyDetailsProps> = (props) => {
	const { societyDetails, societySettings, onCancel, onSubmit } = props;

	const dispatch = useAppDispatch();

	// APIS
	const { data: getSystemIntegratorsResponse } = useGetSystemIntegratorsQuery({});

	const [
		createSociety,
		{
			data: createSocietyResponse,
			isLoading: createSocietyLoading,
			isSuccess: createSocietySuccess,
			error: createSocietyError,
			reset: resetCreateSocietyState
		}
	] = useCreateSocietyMutation();

	const [
		editSociety,
		{
			data: editSocietyResponse,
			isLoading: editSocietyLoading,
			isSuccess: editSocietySuccess,
			error: editSocietyError,
			reset: resetEditSocietyState
		}
	] = useEditSocietyConfigMutation();

	const [
		createSocietySettings,
		{
			data: createSocietySettingsResponse,
			isLoading: createSocietySettingsLoading,
			isSuccess: createSocietySettingsSuccess,
			reset: resetCreateSocietySettingsState
		}
	] = useCreateSocietySettingsMutation();

	const [
		editSocietySettings,
		{
			data: editSocietySettingsResponse,
			isLoading: editSocietySettingsLoading,
			isSuccess: editSocietySettingsSuccess,
			reset: resetEditSocietySettingsState
		}
	] = useEditSocietySettingsMutation();

	const SystemIntegratorsList = useMemo<ISystemIntegratorSelectionDetails[]>(() => {
		if (!getSystemIntegratorsResponse) return [];

		return getSystemIntegratorsResponse.results.map((systemIntegratorItem) => ({
			id: systemIntegratorItem.id,
			name: systemIntegratorItem.name
		}));
	}, [getSystemIntegratorsResponse]);

	const {
		reset,
		control,
		register,
		getValues,
		handleSubmit,
		formState: { errors }
	} = useForm<ISocietyDetailsFormFieldValues>();

	function handleSaveButtonClick(formData: ISocietyDetailsFormFieldValues) {
		if (societyDetails) {
			const editSocietyData: IEditSocietyRequestData = {
				id: societyDetails.id,
				data: {
					name: formData.society_name,
					city: formData.city,
					address_line1: formData.address_line_1,
					address_line2: formData.address_line_2,
					pincode: formData.pincode,
					invoice_no: formData.invoice_number
				}
			};

			if (typeof formData.system_integrator === "number" && formData.system_integrator > 0) {
				editSocietyData.data.system_integrator = formData.system_integrator;
			}

			editSociety(editSocietyData);
		} else {
			const createSocietyData: ICreateSocietyRequestData = {
				name: formData.society_name,
				city: formData.city,
				address_line1: formData.address_line_1,
				address_line2: formData.address_line_2,
				pincode: formData.pincode,
				invoice_no: formData.invoice_number,
				is_synced: false,
				building_config: [],
				contact_details: [],
				enable_secure_login: formData.enable_secure_login
			};

			if (typeof formData.system_integrator === "number" && formData.system_integrator > 0) {
				createSocietyData.system_integrator = formData.system_integrator;
			}

			createSociety(createSocietyData);
		}
	}

	function handleCreateSettings(createdSociety: ISocietyConfigDetails) {
		const formData = getValues();

		if (societySettings) {
			if (createdSociety && formData.connect_start_date && formData.connect_end_date) {
				editSocietySettings({
					id: societySettings.id,
					data: {
						mode: 0,
						subscription_start_date: formData.connect_start_date.format(TIME_FILTER_FORMAT),
						subscription_expiry_date: formData.connect_end_date.format(TIME_FILTER_FORMAT),
						society: createdSociety.id
					}
				});
			} else {
				onSubmit(createdSociety);
				resetEditSocietyState();
			}
		} else {
			if (createdSociety && formData.connect_start_date && formData.connect_end_date) {
				createSocietySettings({
					mode: 0,
					subscription_start_date: formData.connect_start_date.format(TIME_FILTER_FORMAT),
					subscription_expiry_date: formData.connect_end_date.format(TIME_FILTER_FORMAT),
					society: createdSociety.id
				});
			} else {
				onSubmit(createdSociety);
				resetCreateSocietyState();
			}
		}
	}

	useEffect(() => {
		if (societyDetails) {
			if (societySettings) {
				reset({
					society_name: societyDetails.name,
					society_uid: societyDetails.society_uuid,
					system_integrator: societyDetails.system_integrator,
					invoice_number: societyDetails.invoice_no,
					address_line_1: societyDetails.address_line1,
					address_line_2: societyDetails.address_line2,
					pincode: String(societyDetails.pincode),
					city: societyDetails.city,
					connect_start_date: moment(societySettings.subscription_start_date),
					connect_end_date: moment(societySettings.subscription_expiry_date),
					enable_secure_login: false
				});
			} else {
				reset({
					society_name: societyDetails.name,
					society_uid: societyDetails.society_uuid,
					system_integrator: societyDetails.system_integrator,
					invoice_number: societyDetails.invoice_no,
					address_line_1: societyDetails.address_line1,
					address_line_2: societyDetails.address_line2,
					pincode: String(societyDetails.pincode),
					city: societyDetails.city,
					enable_secure_login: false
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [societyDetails, societySettings]);

	useEffect(() => {
		if (createSocietySuccess && createSocietyResponse) {
			// onSubmit(createSocietyResponse);
			// resetCreateSocietyState();
			handleCreateSettings(createSocietyResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createSocietySuccess, createSocietyResponse]);

	useEffect(() => {
		if (editSocietySuccess && editSocietyResponse) {
			handleCreateSettings(editSocietyResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietySuccess, editSocietyResponse]);

	useEffect(() => {
		if (createSocietyError && "data" in createSocietyError) {
			dispatch(
				showFeedbackNotification({
					message: createSocietyError.data ? String(createSocietyError.data) : "Something went wrong",
					severity: "error"
				})
			);
		}
	}, [createSocietyError, dispatch]);

	useEffect(() => {
		if (editSocietyError && "data" in editSocietyError) {
			dispatch(
				showFeedbackNotification({
					message: editSocietyError.data ? String(editSocietyError.data) : "Something went wrong",
					severity: "error"
				})
			);
		}
	}, [editSocietyError, dispatch]);

	useEffect(() => {
		if (
			createSocietySettingsSuccess &&
			createSocietySuccess &&
			createSocietyResponse &&
			createSocietySettingsResponse
		) {
			onSubmit(createSocietyResponse, createSocietySettingsResponse);
			resetCreateSocietyState();
			resetCreateSocietySettingsState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createSocietyResponse, createSocietySettingsSuccess, createSocietySuccess, createSocietySettingsResponse]);

	useEffect(() => {
		if (editSocietySettingsSuccess && editSocietySuccess && editSocietyResponse && editSocietySettingsResponse) {
			onSubmit(editSocietyResponse, editSocietySettingsResponse);
			resetEditSocietyState();
			resetEditSocietySettingsState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyResponse, editSocietySettingsSuccess, editSocietySuccess, editSocietySettingsResponse]);

	return (
		<Grid container rowSpacing={4} columnSpacing={6}>
			<Grid item xs={12} md={6}>
				<InputLabel
					required
					htmlFor="society-name-input"
					error={!!errors.society_name}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					Society Name
				</InputLabel>

				<TextField
					required
					fullWidth
					variant="filled"
					id="society-name-input"
					placeholder="Enter the Society Name"
					error={!!errors.society_name}
					helperText={errors.society_name && errors.society_name.message ? errors.society_name.message.toString() : ""}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("society_name", { required: "Society Name is required" })}
				/>
			</Grid>

			{/* <Grid item xs={12} md={6}>
				<InputLabel
					required
					htmlFor="society-uid-input"
					error={!!errors.society_uid}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					Society UID
				</InputLabel>

				<TextField
					required
					fullWidth
					variant="filled"
					id="society-uid-input"
					placeholder="Enter the Society UID"
					error={!!errors.society_uid}
					helperText={errors.society_uid && errors.society_uid.message ? errors.society_uid.message.toString() : ""}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("society_uid", { required: "Society UID is required" })}
				/>
			</Grid> */}

			<Grid item xs={12} md={6}>
				<InputLabel
					required
					htmlFor="invoice-number-input"
					error={!!errors.invoice_number}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					Invoice Number
				</InputLabel>

				<TextField
					required
					fullWidth
					variant="filled"
					id="invoice-number-input"
					placeholder="Enter the Invoice Number for GG Connect"
					error={!!errors.invoice_number}
					helperText={
						errors.invoice_number && errors.invoice_number.message ? errors.invoice_number.message.toString() : ""
					}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("invoice_number", { required: "Invoice Number is required" })}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<InputLabel
					htmlFor="system-integrator-input"
					error={!!errors.system_integrator}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					System Integrator
				</InputLabel>

				<Controller
					name="system_integrator"
					control={control}
					defaultValue={0}
					render={({ field: { value, onChange } }) => (
						<Autocomplete
							fullWidth
							options={SystemIntegratorsList}
							getOptionLabel={(option) => option.name}
							getOptionKey={(option) => option.id}
							value={SystemIntegratorsList.find((item) => item.id === value) ?? null}
							onChange={(_, updatedValue) => onChange(updatedValue?.id ?? null)}
							id="system-integrator-input"
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									hiddenLabel
									variant="filled"
									placeholder="Select the System Integrator"
									error={!!errors.system_integrator}
									helperText={
										errors.system_integrator && errors.system_integrator.message
											? errors.system_integrator.message.toString()
											: ""
									}
									InputProps={{
										...params.InputProps,
										hiddenLabel: true,
										disableUnderline: true,
										classes: { root: "filled-input-root" }
									}}
								/>
							)}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<InputLabel
					required
					htmlFor="city-input"
					error={!!errors.city}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					City
				</InputLabel>

				<TextField
					required
					fullWidth
					variant="filled"
					id="city-input"
					placeholder="Enter the City name"
					error={!!errors.city}
					helperText={errors.city && errors.city.message ? errors.city.message.toString() : ""}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("city", { required: "City name is required" })}
				/>
			</Grid>

			<Grid item xs={12}>
				<InputLabel
					required
					htmlFor="address-line-1-input"
					error={!!errors.address_line_1}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					Address Line 1
				</InputLabel>

				<TextField
					required
					fullWidth
					variant="filled"
					id="address-line-1-input"
					placeholder="Enter Address Line 1"
					error={!!errors.address_line_1}
					helperText={
						errors.address_line_1 && errors.address_line_1.message ? errors.address_line_1.message.toString() : ""
					}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("address_line_1", { required: "Address Line 1 is required" })}
				/>
			</Grid>

			<Grid item xs={12}>
				<InputLabel
					htmlFor="address-line-2-input"
					error={!!errors.address_line_2}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					Address Line 2
				</InputLabel>

				<TextField
					fullWidth
					variant="filled"
					id="address-line-2-input"
					placeholder="Enter Address Line 2"
					error={!!errors.address_line_2}
					helperText={
						errors.address_line_2 && errors.address_line_2.message ? errors.address_line_2.message.toString() : ""
					}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("address_line_2")}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<InputLabel
					required
					htmlFor="pincode-input"
					error={!!errors.pincode}
					classes={{ root: "add-customer-form-input-label-root" }}
				>
					Pincode
				</InputLabel>

				<NumberInput
					positiveOnly
					disableDecimal
					disableSigned
					required
					fullWidth
					variant="filled"
					id="pincode-input"
					placeholder="Enter the Pincode"
					error={!!errors.pincode}
					helperText={errors.pincode && errors.pincode.message ? errors.pincode.message.toString() : ""}
					InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
					{...register("pincode", {
						required: "Pincode is required",
						pattern: { value: /^[1-9][0-9]{5}$/, message: "Invalid pincode" }
					})}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Box sx={{ display: "flex", alignItems: "flex-end", width: "100%", height: "100%" }}>
					<Controller
						control={control}
						name="enable_secure_login"
						render={({ field: { value, onChange } }) => (
							<FormControl>
								<FormControlLabel
									label={
										<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
											<Typography>Enable secure login for the society</Typography>

											<CheckboxTooltip title="This will enable MFA for this society">
												<InfoOutlinedIcon fontSize="inherit" />
											</CheckboxTooltip>
										</Box>
									}
									control={<Checkbox checked={value} onChange={(_event, checked) => onChange(checked)} />}
								/>
							</FormControl>
						)}
					/>
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Typography variant="subtitle1" fontWeight={500} marginBottom="0.5rem">
					Please enter start and end date for GG Connect
				</Typography>

				<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
					<Controller
						control={control}
						name="connect_start_date"
						rules={{ required: "Start Date is required" }}
						render={({ field: { value, onChange } }) => (
							<DatePicker
								label="From"
								format="DD/MM/YYYY"
								value={value}
								onChange={onChange}
								slotProps={{
									textField: {
										variant: "outlined",
										color: "primary",
										size: "small",
										error: !!errors.connect_start_date,
										helperText:
											errors.connect_start_date && errors.connect_start_date.message
												? errors.connect_start_date.message.toString()
												: ""
									}
								}}
							/>
						)}
					/>

					<Controller
						control={control}
						name="connect_end_date"
						rules={{
							required: "End Date is required",
							validate: (value, formData) => {
								if (value.isBefore(formData.connect_start_date, "day")) {
									return "Expiry date must be greater than start date";
								}

								return true;
							}
						}}
						render={({ field: { value, onChange } }) => (
							<DatePicker
								label="To"
								format="DD/MM/YYYY"
								value={value}
								onChange={onChange}
								slotProps={{
									textField: {
										variant: "outlined",
										color: "primary",
										size: "small",
										error: !!errors.connect_end_date,
										helperText:
											errors.connect_end_date && errors.connect_end_date.message
												? errors.connect_end_date.message.toString()
												: ""
									}
								}}
							/>
						)}
					/>
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Box className="add-customer-form-actions-wrapper">
					<Button variant="outlined" color="error" sx={{ paddingX: 5 }} onClick={onCancel}>
						Cancel
					</Button>

					<LoadingButton
						disableElevation
						variant="contained"
						color="primary"
						onClick={handleSubmit(handleSaveButtonClick)}
						loading={
							createSocietyLoading || editSocietyLoading || createSocietySettingsLoading || editSocietySettingsLoading
						}
					>
						Save and Next
					</LoadingButton>
				</Box>
			</Grid>
		</Grid>
	);
};

export default SocietyDetails;
