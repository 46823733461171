import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

import { IAuthReducerState, ILoggedInUserDetails, ILoginUserRequestData } from "../../types";
import authServices from "../services/auth.services";

const entryptionKey = process.env.REACT_APP_ENCRYPTION_KEY ? String(process.env.REACT_APP_ENCRYPTION_KEY) : "";

const initialState: IAuthReducerState = {
	loading: false,
	success: false,
	error: "",
	currentAction: "",
	unauthorizedError: false,
	user: {
		username: "",
		role: 0,
		token: "",
		full_name: "",
		society: 0
	}
};

// LOGIN USER
export const loginUser = createAsyncThunk<ILoggedInUserDetails, ILoginUserRequestData>(
	"auth/loginUser",
	authServices.handleLoginUser
);

// LOGOUT USER
export const logoutUser = createAsyncThunk<void>("auth/logoutUser", authServices.handleLogoutUser);

const authReducer = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		clearUnauthState: (state) => {
			state.unauthorizedError = false;
			state.user.token = "";
		},

		clearAuthState: (state) => {
			state.unauthorizedError = false;
			state.user = {
				username: "",
				token: "",
				role: 0,
				full_name: "",
				society: 0
			};
		}
	},
	extraReducers: (builder) => {
		// LOGIN USER
		builder.addCase(loginUser.pending, (state) => {
			state.loading = true;
			state.success = false;
			state.error = "";
			state.currentAction = "auth/loginUser";
			state.unauthorizedError = false;
		});

		builder.addCase(loginUser.rejected, (state, { error }) => {
			state.loading = false;
			state.success = false;
			state.error = error.message ?? "Something went wrong";
			state.currentAction = "auth/loginUser";
			state.unauthorizedError = false;
			state.user = {
				token: "",
				username: "",
				role: 0,
				full_name: "",
				society: 0
			};
		});

		builder.addCase(loginUser.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.success = true;
			state.error = "";
			state.currentAction = "auth/loginUser";
			state.unauthorizedError = false;
			state.user = {
				...payload,
				token: CryptoJS.AES.encrypt(payload.token, entryptionKey).toString()
			};
		});

		// LOGOUT USER
		builder.addCase(logoutUser.pending, (state) => {
			state.loading = true;
			state.success = false;
			state.error = "";
			state.currentAction = "auth/logoutUser";
			state.unauthorizedError = false;
		});

		builder.addCase(logoutUser.rejected, (state, { error }) => {
			state.loading = false;
			state.success = false;
			state.error = error.message ?? "Something went wrong";
			state.currentAction = "auth/logoutUser";
			state.unauthorizedError = false;
		});

		builder.addCase(logoutUser.fulfilled, (state) => {
			state.loading = false;
			state.success = true;
			state.error = "";
			state.currentAction = "auth/logoutUser";
			state.unauthorizedError = false;
			state.user = {
				token: "",
				username: "",
				role: 0,
				full_name: "",
				society: 0
			};
		});
	}
});

export const { clearAuthState, clearUnauthState } = authReducer.actions;

export default authReducer;
