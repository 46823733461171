import { Box, LinearProgress, Typography, linearProgressClasses, styled } from "@mui/material";
import React, { FC } from "react";

interface IProgressComponentProps {
	value: number;
	progressText: string;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
	height: 16,
	borderRadius: 8,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: "#DFE5E6"
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 8,
		backgroundColor: "#AABCBD"
	}
}));

const ProgressComponent: FC<IProgressComponentProps> = (props) => {
	const { value, progressText } = props;
	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
			<Typography fontWeight={500}>{`${progressText} ${value}%`}</Typography>
			<BorderLinearProgress variant="determinate" value={value < 2 ? 2 : value} />
		</Box>
	);
};

export default ProgressComponent;
