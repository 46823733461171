import {
	Autocomplete,
	Box,
	Button,
	ClickAwayListener,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	TextField,
	Typography
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import moment from "moment";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoIcon from "@mui/icons-material/Info";

import "./users.css";
import { ReactComponent as InactiveCustomerIcon } from "../../assets/icons/inactive-customer.svg";
import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueFormatterParams
} from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../../utils/constants";
import { useGetUsersMetricsQuery, useGetUsersQuery } from "../../redux/reducers/users.reducer";
import { IGetUsersRequestData, UserStatusType } from "../../types";
import TablePagination from "../../components/TablePagination";
import UserDetailsDialog from "./UserDetailsDialog";
import { useGetAllCustomersQuery } from "../../redux/reducers/settings.reducer";
import { CustomAutocompleteInput, CustomAutocompletePopper } from "../../components/CustomAutocomplete";
import { useLocation } from "react-router-dom";

export interface IUserRowDetails {
	id: number;
	first_name: string;
	last_name: string;
	full_name: string;
	login_id: string;
	customer_id: number;
	customer_name: string;
	contact_number: string;
	role_id: number;
	role: string;
	designation: string;
	status: UserStatusType;
	last_login: string;
	email: string;
	address: string;
	allow_secure_login: boolean;
}

const UsersListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const Users: FC = () => {
	const location = useLocation();

	// RENDERER REFS
	// STATUS DROPDOWN
	const statusDropdownAnchorRef = useRef<HTMLDivElement>(null);

	// CUSTOMER DROPDOWN
	const customerDropdownAnchorRef = useRef<HTMLDivElement>(null);

	// STATES
	const [searchInput, setSearchInput] = useState<string>("");
	const [filters, setFilters] = useState<IGetUsersRequestData>({ page: 1 });

	const [showUserDetailsDialog, setShowUserDetailsDialog] = useState<boolean>(false);
	const [selectedUserDetails, setSelectedUserDetails] = useState<IUserRowDetails | null>(null);

	// RENDERER STATES
	// STATUS DROPDOWN
	const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);

	// CUSTOMER DROPDOWN
	const [customerDropdownOpen, setCustomerDropdownOpen] = useState(false);

	// APIS
	// GET ALL CUSTOMERS
	const { data: getAllCustomersResponse } = useGetAllCustomersQuery();

	// GET USERS
	const {
		data: getUsersResponse,
		isLoading: getUsersLoading,
		isFetching: getUsersFetching,
		refetch: refetchUsers
	} = useGetUsersQuery(filters);

	// GET USERS METRICS
	const { data: getUsersMetricsResponse, refetch: refetchUsersMetrics } = useGetUsersMetricsQuery();

	function refetchAllData() {
		refetchUsers();
		refetchUsersMetrics();
	}

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleSearchUser(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (searchInput) updatedFilters.search = searchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearSearchInput() {
		setSearchInput("");

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handlePageChange(updatedModel: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: updatedModel.page + 1
		}));
	}

	function handleOpenUserDetailsDialog(userDetails: IUserRowDetails | null) {
		setSelectedUserDetails(userDetails);
		setShowUserDetailsDialog(true);
	}

	function handleCloseUserDetailsDialog() {
		setShowUserDetailsDialog(false);
		setSelectedUserDetails(null);
		refetchAllData();
	}

	function getCustomerName() {
		if (getAllCustomersResponse && typeof filters.customer_id === "number") {
			const selectedCustomer = getAllCustomersResponse.find((item) => item.id === filters.customer_id);

			if (selectedCustomer) return selectedCustomer.name;
		}

		return "Customer Name";
	}

	// RENDERER HELPER FUNCTIONS
	// STATUS DROPDOWN
	function handleOpenStatusDropdown() {
		setStatusDropdownOpen(true);
	}

	function handleCloseStatusDropdown() {
		setStatusDropdownOpen(false);
	}

	function handleChangeSelectedStatus(updatedValue: UserStatusType | null) {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (updatedValue) updatedFilters.status = updatedValue;
			else delete updatedFilters.status;

			return updatedFilters;
		});

		handleCloseStatusDropdown();
	}

	// CUSTOMER DROPDOWN
	function handleOpenCustomerDropdown() {
		setCustomerDropdownOpen(true);
	}

	function handleCloseCustomerDropdown() {
		setCustomerDropdownOpen(false);
	}

	function handleChangeSelectedCustomer(updatedValue?: number | null) {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			if (updatedValue && typeof updatedValue === "number") updatedFilters.customer_id = updatedValue;
			else delete updatedFilters.customer_id;

			return updatedFilters;
		});
	}

	function getUsersList(): IUserRowDetails[] {
		if (!getUsersResponse) return [];

		return getUsersResponse.results.map((userItem) => ({
			id: userItem.id,
			first_name: userItem.first_name,
			last_name: userItem.last_name,
			full_name: userItem.full_name,
			login_id: userItem.username,
			customer_id: userItem.customer_id,
			customer_name: userItem.customer_name,
			contact_number: userItem.phone_number,
			role_id: userItem.role,
			role: userItem.role_name,
			designation: userItem.designation,
			status: userItem.status,
			last_login: userItem.last_login ?? "",
			email: userItem.email,
			address: userItem.address,
			allow_secure_login: userItem.allow_secure_login
		}));
	}

	useEffect(() => {
		if (location.state && typeof location.state.customer_id === "number") {
			handleChangeSelectedCustomer(location.state.customer_id);
		}
	}, [location.state]);

	// RENDERER FUNCTIONS
	// STATUS DROPDOWN
	function renderStatusDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					id="status-dropdown"
					aria-controls="status-dropdown-menu"
					aria-haspopup="true"
					aria-expanded={statusDropdownOpen}
					onClick={handleOpenStatusDropdown}
					ref={statusDropdownAnchorRef}
				>
					<Typography variant="subtitle2">{filters.status ? filters.status : "Status"}</Typography>

					{statusDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="status-dropdown-menu"
					anchorEl={statusDropdownAnchorRef.current}
					open={statusDropdownOpen}
					onClose={handleCloseStatusDropdown}
					MenuListProps={{ "aria-labelledby": "status-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedStatus(null)} sx={{ minWidth: 130 }}>
						<em>All</em>
					</MenuItem>

					<MenuItem onClick={() => handleChangeSelectedStatus("Active")}>Active</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedStatus("Frequent")}>Frequent</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedStatus("Inactive")}>Inactive</MenuItem>
				</Menu>
			</Box>
		);
	}

	// CUSTOMER DROPDOWN
	function renderCustomerDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					ref={customerDropdownAnchorRef}
					onClick={handleOpenCustomerDropdown}
					aria-describedby="customer-dropdown-menu"
				>
					<Typography variant="subtitle2">{getCustomerName()}</Typography>
					{customerDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<CustomAutocompletePopper
					id="customer-dropdown-menu"
					open={customerDropdownOpen}
					anchorEl={customerDropdownAnchorRef.current}
					placement="bottom-start"
				>
					<ClickAwayListener onClickAway={handleCloseCustomerDropdown}>
						<Autocomplete
							open
							disableCloseOnSelect
							onClose={(_event, reason) => {
								if (reason === "escape") {
									handleCloseCustomerDropdown();
								}
							}}
							value={
								getAllCustomersResponse ? getAllCustomersResponse.find((item) => item.id === filters.customer_id) : null
							}
							onChange={(_event, updatedValue) => handleChangeSelectedCustomer(updatedValue?.id)}
							options={getAllCustomersResponse ?? []}
							getOptionKey={(item) => item.id}
							getOptionLabel={(item) => item.name}
							// renderInput={(params) => (
							// 	<TextField
							// 		{...params}
							// 		fullWidth
							// 		variant="outlined"
							// 		size="small"
							// 		placeholder="Search"
							// 		// InputProps={{ sx: { padding: "1rem" } }}
							// 	/>
							// )}
							componentsProps={{
								paper: { sx: { borderRadius: 0, borderBottomRightRadius: 6, borderBottomLeftRadius: 6 } }
							}}
							renderInput={(params) => (
								<CustomAutocompleteInput
									ref={params.InputProps.ref}
									inputProps={params.inputProps}
									autoFocus
									placeholder="Search"
									fullWidth
								/>
							)}
						/>
					</ClickAwayListener>
				</CustomAutocompletePopper>
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{
			field: "full_name",
			headerName: "Name",
			flex: 1,
			minWidth: 200,
			sortable: false
		},
		{
			field: "login_id",
			headerName: "Login ID",
			flex: 1,
			minWidth: 120,
			sortable: false
		},
		{
			field: "customer_name",
			headerName: "Customer Name",
			flex: 1,
			minWidth: 200,
			sortable: false,
			renderHeader: renderCustomerDropdown
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "role",
			headerName: "Role",
			flex: 1,
			minWidth: 100,
			sortable: false
		},
		{
			field: "designation",
			headerName: "Designation",
			flex: 1,
			minWidth: 120,
			sortable: false
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 150,
			sortable: false,
			cellClassName: (params) => `user-status-wrapper ${params.value}`,
			renderHeader: renderStatusDropdown,
			renderCell: (params: GridRenderCellParams<IUserRowDetails, UserStatusType>) => (
				<Box className="user-status">
					<Typography variant="body2" color="#000000DE">
						{params.value}
					</Typography>

					{params.value === "Active" ? (
						<FactCheckIcon sx={{ fontSize: "24px" }} />
					) : params.value === "Frequent" ? (
						<TimelapseIcon sx={{ fontSize: "24px" }} />
					) : params.value === "Inactive" ? (
						<InactiveCustomerIcon width={24} />
					) : null}
				</Box>
			)
		},
		{
			field: "last_login",
			headerName: "Last Login",
			flex: 1,
			minWidth: 100,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<string>) =>
				params.value ? moment(params.value).format("DD-MM-YYYY") : "--"
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IUserRowDetails>) => (
				<Box className="users-table-row-actions">
					<IconButton color="inherit" size="small" onClick={() => handleOpenUserDetailsDialog(params.row)}>
						<InfoIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="screen-wrapper">
			<Box className="screen-stats-wrapper">
				<Box className="total-count-wrapper">
					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper">
							<DeveloperBoardIcon sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getUsersMetricsResponse?.total_users ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Users
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box className="category-count-wrapper">
					<Box className="count-wrapper divider-before">
						<Box className="count-icon-wrapper success">
							<FactCheckIcon sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getUsersMetricsResponse?.active_users ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Active Users
							</Typography>
						</Box>
					</Box>

					<Box className="count-wrapper divider-before">
						<Box className="count-icon-wrapper warning">
							<TimelapseIcon sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getUsersMetricsResponse?.frequent_users ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Frequent Users
							</Typography>
						</Box>
					</Box>

					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper error">
							<InactiveCustomerIcon width={42} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getUsersMetricsResponse?.inactive_users ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Inactive Users
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="table-actions-wrapper">
				<Box component="form" noValidate onSubmit={handleSearchUser}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={searchInput}
						onChange={handleChangeSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "table-search-input" },
							endAdornment: (
								<InputAdornment position="end">
									{filters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={() => handleOpenUserDetailsDialog(null)}
				>
					Add
				</Button>
			</Box>

			<Box className="table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getUsersList()}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: UsersListPagination }}
					hideFooter={(getUsersResponse?.count ?? 0) <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					paginationMode="server"
					paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					onPaginationModelChange={handlePageChange}
					rowCount={getUsersResponse?.count ?? 0}
					loading={getUsersLoading || getUsersFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root"
					}}
				/>
			</Box>

			<UserDetailsDialog
				open={showUserDetailsDialog}
				userDetails={selectedUserDetails}
				onClose={handleCloseUserDetailsDialog}
			/>
		</Box>
	);
};

export default Users;
