import { FilledInputProps, InputProps, OutlinedInputProps, TextField, TextFieldProps } from "@mui/material";
import React, { forwardRef } from "react";

interface INumberInputRestProps {
	isContactNumber?: boolean;
	positiveOnly?: boolean;
	disableSigned?: boolean;
	disableDecimal?: boolean;
}

type NumberInputPropsType = INumberInputRestProps & TextFieldProps;

type TextFieldInputPropsType =
	| Partial<FilledInputProps>
	| Partial<OutlinedInputProps>
	| Partial<InputProps>
	| undefined;

const NumberInput = forwardRef<HTMLInputElement, NumberInputPropsType>((props, ref) => {
	const { isContactNumber, positiveOnly, disableSigned, disableDecimal, InputProps, ...restProps } = props;

	function handleWheelEvent(event: React.WheelEvent<HTMLDivElement>) {
		event.currentTarget.querySelector("input")?.blur();
	}

	function handleKeyDownEvent(event: React.KeyboardEvent<HTMLDivElement>) {
		if (event.key === "e" || event.key === "E") {
			event.preventDefault();
		}

		if (disableSigned && (event.key === "-" || event.key === "+")) {
			event.preventDefault();
		}

		if (disableDecimal && event.key === ".") {
			event.preventDefault();
		}
	}

	function handleInputEvent(event: React.FormEvent<HTMLDivElement>) {
		if (isContactNumber) {
			const InputElement = event.target as HTMLInputElement;
			InputElement.value = Math.max(0, parseInt(InputElement.value)).toString().slice(0, 10);
		}
	}

	function getInputProps(): TextFieldInputPropsType {
		const newInputProps: TextFieldInputPropsType = { ...InputProps };

		if (positiveOnly) {
			newInputProps.inputProps = {
				...newInputProps.inputProps,
				min: 0
			};
		}

		return newInputProps;
	}

	return (
		<TextField
			type="number"
			InputProps={getInputProps()}
			onWheel={handleWheelEvent}
			onKeyDown={handleKeyDownEvent}
			onInput={handleInputEvent}
			ref={ref}
			{...restProps}
		/>
	);
});

export default NumberInput;
