import { Container, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { FC, ReactNode, useMemo, useState } from "react";

import "./addCustomerDialog.css";
import ProgressComponent from "../../../components/ProgressComponent";
import SocietyDetails from "./SocietyDetails";
import SocietyConfiguration from "../../../components/SocietyConfiguration";
import { ISocietyConfigDetails, ISocietySettingsResponseData } from "../../../types";
import DeviceRegistration from "./DeviceRegistration";
import ContactDetails from "./ContactDetails";
import UserDetails from "./UserDetails";
import FinalStep from "./FinalStep";

interface IAddCustomerDialogProps {
	open: boolean;
	onClose: () => void;
}

enum ADD_CUSTOMER_FORM_STEP {
	SOCIETY_DETAILS,
	SOCIETY_CONFIG,
	DEVICE_REGISTRATION,
	CONTACT_DETAILS,
	USER_DETAILS,
	FINAL
}

type RenderStepType = {
	[key in ADD_CUSTOMER_FORM_STEP]: ReactNode;
};

const AddCustomerDialog: FC<IAddCustomerDialogProps> = (props) => {
	const { open, onClose } = props;

	const [formStep, setFormStep] = useState<ADD_CUSTOMER_FORM_STEP>(ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS);
	const [societyDetails, setSocietyDetails] = useState<ISocietyConfigDetails | null>(null);
	const [societySettings, setSocietySettings] = useState<ISocietySettingsResponseData | null>(null);

	const DialogHeader = useMemo<string>(() => {
		switch (formStep) {
			case ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS:
				return "Society Details";

			case ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG:
				return "Society Configuration";

			case ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION:
				return "Device Registration";

			case ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS:
				return "Contact Details";

			case ADD_CUSTOMER_FORM_STEP.USER_DETAILS:
				return "User Details";

			case ADD_CUSTOMER_FORM_STEP.FINAL:
				return "Society Configuration";

			default:
				return "Add Customer";
		}
	}, [formStep]);

	const AddCustomerProgress = useMemo<number>(() => {
		switch (formStep) {
			case ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS:
				return 10;

			case ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG:
				return 40;

			case ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION:
				return 55;

			case ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS:
				return 69;

			case ADD_CUSTOMER_FORM_STEP.USER_DETAILS:
				return 88;

			case ADD_CUSTOMER_FORM_STEP.FINAL:
				return 99;

			default:
				return 0;
		}
	}, [formStep]);

	function updadateSocietyDetails(updatedSocietyDetails: ISocietyConfigDetails) {
		setSocietyDetails(updatedSocietyDetails);
	}

	function handleClose() {
		onClose();
		setSocietyDetails(null);
		setSocietySettings(null);
	}

	function handleNextStep() {
		setFormStep((currentFormStep) => {
			switch (currentFormStep) {
				case ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS:
					return ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG;

				case ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG:
					return ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION;

				case ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION:
					return ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS;

				case ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS:
					return ADD_CUSTOMER_FORM_STEP.USER_DETAILS;

				case ADD_CUSTOMER_FORM_STEP.USER_DETAILS:
					return ADD_CUSTOMER_FORM_STEP.FINAL;

				case ADD_CUSTOMER_FORM_STEP.FINAL:
					handleClose();
					return ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS;

				default:
					return currentFormStep;
			}
		});
	}

	function handlePreviousStep() {
		setFormStep((currentFormStep) => {
			switch (currentFormStep) {
				case ADD_CUSTOMER_FORM_STEP.FINAL:
					return ADD_CUSTOMER_FORM_STEP.USER_DETAILS;

				case ADD_CUSTOMER_FORM_STEP.USER_DETAILS:
					return ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS;

				case ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS:
					return ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION;

				case ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION:
					return ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG;

				case ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG:
					return ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS;

				case ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS:
					handleClose();
					return ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS;

				default:
					return currentFormStep;
			}
		});
	}

	function handleSubmitSocietyDetails(
		createdSocietyDetails: ISocietyConfigDetails,
		createdSocietySettings?: ISocietySettingsResponseData
	) {
		setSocietyDetails(createdSocietyDetails);

		if (createdSocietySettings) {
			setSocietySettings(createdSocietySettings);
		} else {
			setSocietySettings(null);
		}

		handleNextStep();
	}

	const RenderStep: RenderStepType = {
		[ADD_CUSTOMER_FORM_STEP.SOCIETY_DETAILS]: (
			<SocietyDetails
				societyDetails={societyDetails}
				societySettings={societySettings}
				onCancel={handlePreviousStep}
				onSubmit={handleSubmitSocietyDetails}
			/>
		),
		[ADD_CUSTOMER_FORM_STEP.SOCIETY_CONFIG]: (
			<SocietyConfiguration
				societyDetails={societyDetails}
				onSubmit={handleNextStep}
				onCancel={handlePreviousStep}
				onChangeSocietyDetails={updadateSocietyDetails}
			/>
		),
		[ADD_CUSTOMER_FORM_STEP.DEVICE_REGISTRATION]: (
			<DeviceRegistration societyDetails={societyDetails} onSubmit={handleNextStep} onCancel={handlePreviousStep} />
		),
		[ADD_CUSTOMER_FORM_STEP.CONTACT_DETAILS]: (
			<ContactDetails
				societyDetails={societyDetails}
				onSubmit={handleNextStep}
				onCancel={handlePreviousStep}
				onChangeSocietyDetails={updadateSocietyDetails}
			/>
		),
		[ADD_CUSTOMER_FORM_STEP.USER_DETAILS]: (
			<UserDetails societyDetails={societyDetails} onSubmit={handleNextStep} onCancel={handlePreviousStep} />
		),
		[ADD_CUSTOMER_FORM_STEP.FINAL]: <FinalStep onSubmit={handleNextStep} />
	};

	return (
		<Dialog open={open} fullScreen disableEscapeKeyDown disableRestoreFocus>
			<DialogTitle textAlign="center" variant="h4" fontWeight={500} classes={{ root: "dialog-title-root primary" }}>
				{DialogHeader}
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Container maxWidth="xl" sx={{ display: "flex", flexDirection: "column", height: "100%", gap: "1rem" }}>
					<ProgressComponent value={AddCustomerProgress} progressText="Gate-Guard Society Setup" />

					{RenderStep[formStep]}
				</Container>
			</DialogContent>
		</Dialog>
	);
};

export default AddCustomerDialog;
