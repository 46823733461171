import {
	GridColDef,
	GridLogicOperator,
	GridPagination,
	GridRenderCellParams,
	GridSortModel,
	GridValueFormatterParams
} from "@mui/x-data-grid";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FormEvent, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";

import TablePagination from "../../components/TablePagination";
import { useGetDeviceStatsQuery } from "../../redux/reducers/devices.reducer";
import { DeviceStatusType, IDeviceCameraDetails } from "../../types";
import moment from "moment";
import ConnectedCamerasDialog from "../Devices/ConnectedCamerasDialog";
import { IDeviceRowDetails } from "../Devices";
import { DataGridPremium } from "@mui/x-data-grid-premium";

interface IStatusMonitorDetails {
	id: number;
	society_name: string;
	device_name: string;
	status: DeviceStatusType;
	offline_from: string | null;
	last_entry: string | null;
	last_exit: string | null;
	last_auth_entry: string | null;
	last_auth_exit: string | null;
	camera_details: IDeviceCameraDetails;
}

const StatusMonitorPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const StatusMonitor = () => {
	const [searchInput, setSearchInput] = useState<string>("");
	const [filters, setFilters] = useState<{ search?: string }>({});
	const [showConnectedCamerasDetailsDialog, setShowConnectedCamerasDetailsDialog] = useState<boolean>(false);
	const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<IDeviceRowDetails | null>(null);
	const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([{ field: "society_name", sort: "asc" }]);

	// APIS
	const {
		data: getDeviceStatusResponse,
		isLoading: getDeviceStatusLoading,
		isFetching: getDeviceStatusFetching
	} = useGetDeviceStatsQuery();

	const rowsData = useMemo<IStatusMonitorDetails[]>(() => {
		const rows: IStatusMonitorDetails[] = [];

		if (getDeviceStatusResponse) {
			for (const item of getDeviceStatusResponse) {
				if (!item.device_id) continue;

				rows.push({
					id: item.device_id,
					society_name: item.society_name,
					device_name: item.device_name,
					status: item.device_status,
					offline_from: item.offline_from,
					last_entry: item.last_entry_time ? item.last_entry_time : null,
					last_exit: item.last_exit_time ? item.last_exit_time : null,
					last_auth_entry: item.last_auth_entry_time ? item.last_auth_entry_time : null,
					last_auth_exit: item.last_auth_exit_time ? item.last_auth_exit_time : null,
					camera_details: item.camera_names
				});
			}
		}

		return rows;
	}, [getDeviceStatusResponse]);

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleSearchDevice(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			if (searchInput) updatedFilters.search = searchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearSearchInput() {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handleOpenCamerasDialog(deviceDetails: IStatusMonitorDetails) {
		setSelectedDeviceDetails({
			id: deviceDetails.id,
			customer_id: 0,
			customer_name: "",
			device_name: deviceDetails.device_name,
			global_ip: "",
			status: deviceDetails.status,
			cameras_count: 0,
			serial_number: "",
			local_ip: "",
			barrier_type: 0,
			camera_names: deviceDetails.camera_details,
			release_version: ""
		});
		setShowConnectedCamerasDetailsDialog(true);
	}

	function handleCloseCamerasDialog() {
		setShowConnectedCamerasDetailsDialog(false);
		setSelectedDeviceDetails(null);
	}

	function handleChangeTableSortingModel(updatedModel: GridSortModel) {
		setTableSortingModel(updatedModel);
	}

	const columnsData: GridColDef[] = [
		{
			field: "society_name",
			headerName: "Customer Name",
			flex: 1,
			minWidth: 170
		},
		{
			field: "device_name",
			headerName: "Device Name",
			flex: 1,
			minWidth: 150
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 210,
			renderCell: (params: GridRenderCellParams<IStatusMonitorDetails, DeviceStatusType>) => (
				<Box className={`device-status ${params.value}`}>
					<FiberManualRecordIcon sx={{ fontSize: "18px" }} />

					<Typography variant="body2" color="#000000DE">
						{params.value}

						{params.row.offline_from ? (
							<span>{` (from ${moment.duration(moment().diff(moment(params.row.offline_from))).humanize()})`}</span>
						) : null}
					</Typography>
				</Box>
			),
			sortComparator: (value1: DeviceStatusType, value2: DeviceStatusType, cellParams1, cellParams2) => {
				const comparator = value1.localeCompare(value2);

				if (comparator === 0) {
					const row1: IStatusMonitorDetails = cellParams1.api.getRow(cellParams1.id);
					const row2: IStatusMonitorDetails = cellParams2.api.getRow(cellParams2.id);

					return (row1.offline_from ?? "9").localeCompare(row2.offline_from ?? "9");
				}

				return comparator;
			}
		},
		{
			field: "last_entry",
			headerName: "Last Entry",
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<string | null>) =>
				params.value ? `${moment.duration(moment().diff(moment(params.value))).humanize()} ago` : "--"
		},
		{
			field: "last_exit",
			headerName: "Last Exit",
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<string | null>) =>
				params.value ? `${moment.duration(moment().diff(moment(params.value))).humanize()} ago` : "--"
		},
		{
			field: "last_auth_entry",
			headerName: "Last Auth Entry",
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<string | null>) =>
				params.value ? `${moment.duration(moment().diff(moment(params.value))).humanize()} ago` : "--"
		},
		{
			field: "last_auth_exit",
			headerName: "Last Auth Exit",
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<string | null>) =>
				params.value ? `${moment.duration(moment().diff(moment(params.value))).humanize()} ago` : "--"
		},
		{
			field: "check_cameras",
			headerName: "",
			width: 150,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Box className="check-cameras-cell">
					<Button
						variant="text"
						size="small"
						color="info"
						sx={{ textTransform: "capitalize" }}
						endIcon={<KeyboardArrowRightIcon />}
						// disabled={params.row.status === "Offline"}
						onClick={() => handleOpenCamerasDialog(params.row)}
					>
						Check Cameras
					</Button>
				</Box>
			)
		}
	];

	return (
		<Box className="screen-wrapper">
			<Box className="table-actions-wrapper">
				<Box component="form" noValidate onSubmit={handleSearchDevice}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={searchInput}
						onChange={handleChangeSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "table-search-input" },
							endAdornment: (
								<InputAdornment position="end">
									{filters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>
			</Box>

			<Box className="table-wrapper">
				<DataGridPremium
					columns={columnsData}
					rows={rowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[100]}
					slots={{ pagination: StatusMonitorPagination }}
					hideFooter={(getDeviceStatusResponse?.length ?? 0) <= 100}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					// paginationMode="server"
					// paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					// onPaginationModelChange={handlePageChange}
					// rowCount={getDevicesResponse?.count ?? 0}
					loading={getDeviceStatusLoading || getDeviceStatusFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header`,
						root: "table-root"
					}}
					filterModel={{
						logicOperator: GridLogicOperator.Or,
						items: filters.search
							? [
									{ field: "device_name", operator: "contains", value: filters.search, id: "device_name" },
									{ field: "society_name", operator: "contains", value: filters.search, id: "society_name" }
							  ]
							: []
					}}
					disableColumnFilter
					sortModel={tableSortingModel}
					onSortModelChange={handleChangeTableSortingModel}
					disableMultipleColumnsSorting
				/>
			</Box>

			<ConnectedCamerasDialog
				open={showConnectedCamerasDetailsDialog}
				deviceDetails={selectedDeviceDetails}
				onClose={handleCloseCamerasDialog}
			/>
		</Box>
	);
};

export default StatusMonitor;
