import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { DataGrid, GridColDef, GridPagination, GridPaginationModel, GridRenderCellParams } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";

import "./systemIntegrators.css";
import { ROWS_PER_PAGE } from "../../utils/constants";
import {
	useGetSystemIntegratorsMetricsQuery,
	useGetSystemIntegratorsQuery
} from "../../redux/reducers/systemIntegrators.reducer";
import { IGetSystemIntegratorsRequestData, ISystemIntegratorContactDetails } from "../../types";
import TablePagination from "../../components/TablePagination";
import SystemIntegratorDetailsDialog from "./SystemIntegratorDetailsDialog";

export interface ISystemIntegratorRowDetails {
	id: number;
	name: string;
	city: string;
	state: string;
	contact_person: string;
	contact_number: string;
	customers_count: number;
	devices_count: number;
	contacts: ISystemIntegratorContactDetails[];
	areas_covered: string;
	address: string;
	designation: string;
	support_email: string;
	support_phone_number: string;
	partner_logo: string;
}

const SystemIntegratorsListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const SystemIntegrators: FC = () => {
	const [searchInput, setSearchInput] = useState<string>("");
	const [filters, setFilters] = useState<IGetSystemIntegratorsRequestData>({ page: 1 });

	const [showSystemIntegratorDetailsDialog, setShowSystemIntegratorDetailsDialog] = useState<boolean>(false);
	const [selectedSystemIntegratorDetails, setSelectedSystemIntegratorDetails] =
		useState<ISystemIntegratorRowDetails | null>(null);

	// APIS
	// GET SYSTEM INTEGRATORS
	const {
		data: getSystemIntegratorsResponse,
		isLoading: getSystemIntegratorsLoading,
		isFetching: getSystemIntegratorsFetching,
		refetch: refetchSystemIntegrators
	} = useGetSystemIntegratorsQuery(filters);

	// GET SYSTEM INTEGRATORS METRICS
	const { data: getSystemIntegratorsMetricsResponse, refetch: refetchSystemIntegratorsMetrics } =
		useGetSystemIntegratorsMetricsQuery();

	function refetchAllData() {
		refetchSystemIntegrators();
		refetchSystemIntegratorsMetrics();
	}

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleSearchSystemIntegrator(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (searchInput) updatedFilters.search = searchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearSearchInput() {
		setSearchInput("");
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handlePageChange(updatedModel: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: updatedModel.page + 1
		}));
	}

	function handleOpenSystemIntegratorDetailsDialog(systemIntegratorDetails: ISystemIntegratorRowDetails | null) {
		setSelectedSystemIntegratorDetails(systemIntegratorDetails);
		setShowSystemIntegratorDetailsDialog(true);
	}

	function handleCloseSystemIntegratorDetailsDialog() {
		setShowSystemIntegratorDetailsDialog(false);
		setSelectedSystemIntegratorDetails(null);
		refetchAllData();
	}

	function getSystemIntegratorsList(): ISystemIntegratorRowDetails[] {
		if (!getSystemIntegratorsResponse) return [];

		return getSystemIntegratorsResponse.results.map((systemIntegratorItem) => {
			const contactDetails =
				systemIntegratorItem.contact_details.find((item) => item.is_primary_user) ??
				systemIntegratorItem.contact_details[0];

			return {
				id: systemIntegratorItem.id,
				name: systemIntegratorItem.name,
				city: systemIntegratorItem.city,
				state: systemIntegratorItem.state,
				contact_person: contactDetails?.name ?? "",
				contact_number: contactDetails?.contact_number ?? "",
				customers_count: systemIntegratorItem.customer_count,
				devices_count: systemIntegratorItem.device_count,
				contacts: systemIntegratorItem.contact_details,
				areas_covered: systemIntegratorItem.area_covered.join(", "),
				address: systemIntegratorItem.address,
				designation: systemIntegratorItem.designation,
				support_email: systemIntegratorItem.support_email ?? "",
				support_phone_number: systemIntegratorItem.support_phone_number ?? "",
				partner_logo: systemIntegratorItem.logo
			};
		});
	}

	const columnsData: GridColDef[] = [
		{
			field: "name",
			headerName: "System Integrator",
			flex: 2,
			minWidth: 200,
			sortable: false
		},
		{
			field: "city",
			headerName: "City",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "state",
			headerName: "State",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "contact_person",
			headerName: "Contact Person",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			flex: 1,
			minWidth: 130,
			sortable: false
		},
		{
			field: "customers_count",
			headerName: "Customers",
			flex: 1,
			minWidth: 100,
			sortable: false
		},
		{
			field: "devices_count",
			headerName: "Devices",
			flex: 1,
			minWidth: 100,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ISystemIntegratorRowDetails>) => (
				<Box className="system-integrators-table-row-actions">
					<IconButton color="inherit" size="small" onClick={() => handleOpenSystemIntegratorDetailsDialog(params.row)}>
						<InfoIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="screen-wrapper">
			<Box className="screen-stats-wrapper">
				<Box className="total-count-wrapper">
					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper">
							<DeveloperBoardIcon sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getSystemIntegratorsMetricsResponse?.total_count ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								System Integrators
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box sx={{ flex: 0.75 }}></Box>
			</Box>

			<Box className="table-actions-wrapper">
				<Box component="form" noValidate onSubmit={handleSearchSystemIntegrator}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={searchInput}
						onChange={handleChangeSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "table-search-input" },
							endAdornment: (
								<InputAdornment position="end">
									{filters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={() => handleOpenSystemIntegratorDetailsDialog(null)}
				>
					Add
				</Button>
			</Box>

			<Box className="table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getSystemIntegratorsList()}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: SystemIntegratorsListPagination }}
					hideFooter={(getSystemIntegratorsResponse?.count ?? 0) <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					paginationMode="server"
					paginationModel={{ page: (filters.page ?? 1) - 1, pageSize: ROWS_PER_PAGE }}
					onPaginationModelChange={handlePageChange}
					rowCount={getSystemIntegratorsResponse?.count ?? 0}
					loading={getSystemIntegratorsLoading || getSystemIntegratorsFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root"
					}}
				/>
			</Box>

			<SystemIntegratorDetailsDialog
				open={showSystemIntegratorDetailsDialog}
				systemIntegratorDetails={selectedSystemIntegratorDetails}
				onClose={handleCloseSystemIntegratorDetailsDialog}
			/>
		</Box>
	);
};

export default SystemIntegrators;
