import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as OTSCompleteImage } from "../../../assets/images/ots-complete.svg";

interface IFinalStepProps {
	onSubmit: () => void;
}

const FinalStep: FC<IFinalStepProps> = (props) => {
	const { onSubmit } = props;

	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "3rem" }}>
			<Box
				sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1rem" }}
			>
				<OTSCompleteImage height={325} />

				<Typography variant="h4" color="var(--color-primary-main)" fontWeight={500}>
					Gate-Guard Setup Complete
				</Typography>
			</Box>

			<Box
				sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1rem" }}
			>
				<Button disableElevation variant="contained" color="primary" onClick={onSubmit}>
					Finalize Setup
				</Button>

				{/* <Button color="error" onClick={handleBackButtonClick}>
					Back
				</Button> */}
			</Box>
		</Box>
	);
};

export default FinalStep;
