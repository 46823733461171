import CryptoJS from "crypto-js";
import { store } from "../redux";

// export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? "";
export const BASE_API_URL = `${window.location.protocol}//${window.location.hostname}/api/v1`;

// API AUTH TOKEN
const API_REQUEST_HEADER_OPTIONS = {
	// "X-Cue": "34db55e07f7b39df480284397f7f42ec",
	// "X-Salt": "683239",
	// "X-Alpha": "21"
};

function getAuthToken() {
	const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY ? String(process.env.REACT_APP_ENCRYPTION_KEY) : "";
	const encryptedToken: string = store.getState().auth.user.token;

	const token = CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8);
	return token ? `token ${token}` : "";
}

export const getAPIHeaderOptions = (isAuthRequired = false) => {
	const token = getAuthToken();

	if (isAuthRequired && token) {
		return {
			...API_REQUEST_HEADER_OPTIONS,
			Authorization: token
		};
	}

	return API_REQUEST_HEADER_OPTIONS;
};

export const getDefaultParams = () => {
	const societyId = store.getState().auth.user.society;

	if (typeof societyId === "number" && societyId >= 0) {
		return { society: societyId };
	}

	return {};
};
