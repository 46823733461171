import { Box, Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useRef, useState } from "react";
import moment from "moment";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LaunchIcon from "@mui/icons-material/Launch";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import "./customers.css";
import { ReactComponent as InactiveCustomerIcon } from "../../assets/icons/inactive-customer.svg";
import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueFormatterParams
} from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../../utils/constants";
import { useGetCustomersMetricsQuery, useGetCustomersQuery } from "../../redux/reducers/customers.reducer";
import {
	CustomerStatusType,
	IBuildingConfigDetails,
	ICustomerContactDetails,
	IGetCustomersRequestData
} from "../../types";
import TablePagination from "../../components/TablePagination";
import AddCustomerDialog from "./AddCustomerDialog";
import CustomerDetailsDialog from "./CustomerDetailsDialog";
import { useNavigate } from "react-router-dom";

export interface ICustomerRowDetails {
	id: number;
	customer_name: string;
	system_integrator: string;
	devices_count: number;
	users_count: number;
	start_date: string | null;
	expiry_date: string | null;
	days_left: number;
	status: CustomerStatusType;
	previous_invoice_number: string;
	contact_details: ICustomerContactDetails[];
	building_config: IBuildingConfigDetails[];
	enable_secure_login: boolean;
}

const CustomersListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

enum DAYS_LEFT_SORT_KEYS_E {
	ASCENDING = "days_left",
	DESCENDING = "-days_left"
}

const Customers: FC = () => {
	const navigate = useNavigate();

	// RENDERER REFS
	// DAYS LEFT SORTING DROPDOWN
	const daysLeftSortDropdownAnchorRef = useRef<HTMLButtonElement>(null);

	// STATUS DROPDOWN
	const statusDropdownAnchorRef = useRef<HTMLDivElement>(null);

	// STATES
	const [searchInput, setSearchInput] = useState<string>("");
	const [filters, setFilters] = useState<IGetCustomersRequestData>({ page: 1 });
	const [showAddCustomerDialog, setShowAddCustomerDialog] = useState<boolean>(false);

	const [selectedCustomerDetails, setSelectedCustomerDetails] = useState<ICustomerRowDetails | null>(null);

	// RENDERER STATES
	// DAYS LEFT SORTING DROPDOWN
	const [daysLeftSortDropdownOpen, setDaysLeftSortDropdownOpen] = useState<boolean>(false);

	// STATUS DROPDOWN
	const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);

	// APIS
	// GET CUSTOMERS
	const {
		data: getCustomersResponse,
		isLoading: getCustomersLoading,
		isFetching: getCustomersFetching,
		refetch: refetchCustomers
	} = useGetCustomersQuery(filters);

	// GET CUSTOMERS METRICS
	const { data: getCustomersMetricsResponse, refetch: refetchCustomerMetrics } = useGetCustomersMetricsQuery();

	function refetchAllData() {
		refetchCustomers();
		refetchCustomerMetrics();
	}

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handlePageChange(updatedModel: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: updatedModel.page + 1
		}));
	}

	function handleSearchCustomer(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (searchInput) updatedFilters.search = searchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearSearchInput() {
		setSearchInput("");
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handleOpenAddCustomerDialog() {
		setShowAddCustomerDialog(true);
	}

	function handleCloseAddCustomerDialog() {
		setShowAddCustomerDialog(false);
		refetchAllData();
	}

	function handleOpenCustomerDetailsDialog(customerDetails: ICustomerRowDetails) {
		setSelectedCustomerDetails(customerDetails);
	}

	function handleCloseCustomerDetailsDialog() {
		setSelectedCustomerDetails(null);
		refetchAllData();
	}

	function handleDeviceButtonClick(customerId: number) {
		navigate("/devices", { state: { customer_id: customerId } });
	}

	function handleUserButtonClick(customerId: number) {
		navigate("/users", { state: { customer_id: customerId } });
	}

	// RENDERER HELPER FUNCTIONS
	// DAYS LEFT SORTING DROPDOWN
	function handleOpenDaysLeftSortDropdown() {
		setDaysLeftSortDropdownOpen(true);
	}

	function handleCloseDaysLeftSortDropdown() {
		setDaysLeftSortDropdownOpen(false);
	}

	function handleChangeSelectedDaysLeftSort(updatedValue: "asc" | "desc" | null) {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (updatedValue) {
				if (updatedValue === "asc") updatedFilters.order_by = DAYS_LEFT_SORT_KEYS_E.ASCENDING;
				else if (updatedValue === "desc") updatedFilters.order_by = DAYS_LEFT_SORT_KEYS_E.DESCENDING;
			} else {
				delete updatedFilters.order_by;
			}

			// if (updatedValue) updatedFilters.days_left_sort = updatedValue;
			// else delete updatedFilters.days_left_sort;

			return updatedFilters;
		});

		handleCloseDaysLeftSortDropdown();
	}

	// STATUS DROPDOWN
	function handleOpenStatusDropdown() {
		setStatusDropdownOpen(true);
	}

	function handleCloseStatusDropdown() {
		setStatusDropdownOpen(false);
	}

	function handleChangeSelectedStatus(updatedValue: CustomerStatusType | null) {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters, page: 1 };

			if (updatedValue) updatedFilters.status = updatedValue;
			else delete updatedFilters.status;

			return updatedFilters;
		});

		handleCloseStatusDropdown();
	}

	function getCustomersList(): ICustomerRowDetails[] {
		if (!getCustomersResponse) return [];

		return getCustomersResponse.results.map((customerItem) => ({
			id: customerItem.id,
			customer_name: customerItem.name,
			system_integrator: customerItem.system_integrator_name,
			devices_count: customerItem.device_count ?? 0,
			users_count: customerItem.user_count ?? 0,
			start_date: customerItem.start_date,
			expiry_date: customerItem.expiry_date,
			days_left: customerItem.days_left,
			status: customerItem.status,
			previous_invoice_number: customerItem.invoice_no,
			contact_details: customerItem.contact_details,
			building_config: customerItem.building_config,
			enable_secure_login: customerItem.enable_secure_login
		}));
	}

	// RENDERER FUNCTIONS
	// DAYS LEFT SORTING DROPDOWN
	function renderDaysLeftSortDropdown() {
		return (
			<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
				<Typography variant="subtitle2">Days Left</Typography>

				<IconButton
					size="small"
					id="days-left-sort-dropdown"
					aria-controls="days-left-sort-dropdown-menu"
					aria-haspopup="true"
					aria-expanded={daysLeftSortDropdownOpen}
					onClick={handleOpenDaysLeftSortDropdown}
					ref={daysLeftSortDropdownAnchorRef}
				>
					{filters.order_by ? (
						filters.order_by === DAYS_LEFT_SORT_KEYS_E.ASCENDING ? (
							<ArrowUpwardIcon fontSize="small" />
						) : (
							<ArrowDownwardIcon fontSize="small" />
						)
					) : (
						<FilterListIcon fontSize="small" />
					)}
				</IconButton>

				<Menu
					id="days-left-sort-dropdown-menu"
					anchorEl={daysLeftSortDropdownAnchorRef.current}
					open={daysLeftSortDropdownOpen}
					onClose={handleCloseDaysLeftSortDropdown}
					MenuListProps={{ "aria-labelledby": "days-left-sort-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedDaysLeftSort("asc")}>Ascending</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedDaysLeftSort("desc")}>Descending</MenuItem>
				</Menu>
			</Box>
		);
	}

	// STATUS DROPDOWN
	function renderStatusDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					id="status-dropdown"
					aria-controls="status-dropdown-menu"
					aria-haspopup="true"
					aria-expanded={statusDropdownOpen}
					onClick={handleOpenStatusDropdown}
					ref={statusDropdownAnchorRef}
				>
					<Typography variant="subtitle2">{filters.status ? filters.status : "Status"}</Typography>

					{statusDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="status-dropdown-menu"
					anchorEl={statusDropdownAnchorRef.current}
					open={statusDropdownOpen}
					onClose={handleCloseStatusDropdown}
					MenuListProps={{ "aria-labelledby": "status-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedStatus(null)} sx={{ minWidth: 100 }}>
						<em>All</em>
					</MenuItem>

					<MenuItem onClick={() => handleChangeSelectedStatus("Active")}>Active</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedStatus("Renewal")}>Renewal</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedStatus("Inactive")}>Inactive</MenuItem>
				</Menu>
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{
			field: "customer_name",
			headerName: "Customer Name",
			flex: 1,
			minWidth: 120,
			sortable: false
		},
		{
			field: "system_integrator",
			headerName: "System Integrator",
			flex: 1,
			minWidth: 120,
			sortable: false
		},
		{
			field: "devices_count",
			headerName: "Devices",
			width: 75,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ICustomerRowDetails, number>) => (
				<Box className="counts-cell-wrapper">
					<Typography variant="body2">{params.value}</Typography>

					<IconButton color="info" size="small" onClick={() => handleDeviceButtonClick(params.row.id)}>
						<LaunchIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		},
		{
			field: "users_count",
			headerName: "Users",
			width: 75,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ICustomerRowDetails, number>) => (
				<Box className="counts-cell-wrapper">
					<Typography variant="body2">{params.value}</Typography>

					<IconButton color="info" size="small" onClick={() => handleUserButtonClick(params.row.id)}>
						<LaunchIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		},
		{
			field: "start_date",
			headerName: "Start Date",
			flex: 1,
			minWidth: 120,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<string | null>) =>
				params.value ? moment(params.value).format("DD-MM-YYYY") : "--"
		},
		{
			field: "expiry_date",
			headerName: "Expiry Date",
			flex: 1,
			minWidth: 120,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<string | null>) =>
				params.value ? moment(params.value).format("DD-MM-YYYY") : "--"
		},
		{
			field: "days_left",
			headerName: "Days Left",
			flex: 1,
			minWidth: 120,
			sortable: false,
			renderHeader: renderDaysLeftSortDropdown,
			valueFormatter: (params: GridValueFormatterParams<number>) =>
				params.value >= 0 ? params.value : `(${params.value * -1})`
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 120,
			sortable: false,
			renderHeader: renderStatusDropdown,
			renderCell: (params: GridRenderCellParams<ICustomerRowDetails, CustomerStatusType>) => (
				<Box className="customer-status">
					<Typography variant="body2" color="#000000DE">
						{params.value}
					</Typography>

					{params.value === "Active" ? (
						<FactCheckIcon sx={{ fontSize: "24px" }} />
					) : params.value === "Renewal" ? (
						<TimelapseIcon sx={{ fontSize: "24px" }} />
					) : params.value === "Inactive" ? (
						<InactiveCustomerIcon width={24} />
					) : null}
				</Box>
			),
			cellClassName: (params) => `customer-status-wrapper ${params.value}`
		},
		{
			field: "actions",
			headerName: "",
			width: 75,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ICustomerRowDetails>) => (
				<Box className="customer-table-row-actions">
					<IconButton color="inherit" size="small">
						<DashboardIcon fontSize="inherit" />
					</IconButton>

					<IconButton color="inherit" size="small" onClick={() => handleOpenCustomerDetailsDialog(params.row)}>
						<InfoIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="screen-wrapper">
			<Box className="screen-stats-wrapper">
				<Box className="total-count-wrapper">
					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper">
							<ApartmentIcon color="primary" sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getCustomersMetricsResponse?.total_customers ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Customers
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box className="category-count-wrapper">
					<Box className="count-wrapper divider-before">
						<Box className="count-icon-wrapper success">
							<FactCheckIcon color="success" sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getCustomersMetricsResponse?.active_customers ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Active
							</Typography>
						</Box>
					</Box>

					<Box className="count-wrapper divider-before">
						<Box className="count-icon-wrapper warning">
							<TimelapseIcon color="warning" sx={{ fontSize: "42px" }} />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getCustomersMetricsResponse?.renewal_customers ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Renewal
							</Typography>
						</Box>
					</Box>

					<Box className="count-wrapper divider-before divider-after">
						<Box className="count-icon-wrapper error">
							<InactiveCustomerIcon width={42} fill="var(--color-error-main)" />
						</Box>

						<Box className="count-text-wrapper">
							<Typography variant="h4" fontWeight={500}>
								{getCustomersMetricsResponse?.inactive_customers ?? 0}
							</Typography>

							<Typography variant="body2" color="#00000061">
								Inactive
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="table-actions-wrapper">
				<Box component="form" noValidate onSubmit={handleSearchCustomer}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={searchInput}
						onChange={handleChangeSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "table-search-input" },
							endAdornment: (
								<InputAdornment position="end">
									{filters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={handleOpenAddCustomerDialog}
				>
					Add
				</Button>
			</Box>

			<Box className="table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getCustomersList()}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: CustomersListPagination }}
					hideFooter={(getCustomersResponse?.count ?? 0) <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					paginationMode="server"
					paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					onPaginationModelChange={handlePageChange}
					rowCount={getCustomersResponse?.count ?? 0}
					loading={getCustomersLoading || getCustomersFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header`,
						root: "table-root"
					}}
				/>
			</Box>

			<AddCustomerDialog open={showAddCustomerDialog} onClose={handleCloseAddCustomerDialog} />

			<CustomerDetailsDialog customerDetails={selectedCustomerDetails} onClose={handleCloseCustomerDetailsDialog} />
		</Box>
	);
};

export default Customers;
