import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IFeedbackNotificationReducerState } from "../../types";
import { AlertProps } from "@mui/material";

interface IShowFeedbackNotificationPayload {
	message: string;
	severity?: AlertProps["severity"];
}

const initialState: IFeedbackNotificationReducerState = {
	show: false,
	message: ""
};

const feedbackNotificationReducer = createSlice({
	name: "feedbackNotification",
	initialState: initialState,
	reducers: {
		showFeedbackNotification: (state, { payload }: PayloadAction<IShowFeedbackNotificationPayload>) => {
			state.show = true;
			state.message = payload.message;
			state.severity = payload.severity;
		},
		hideFeedbackNotification: (state) => {
			state.show = false;
		}
	}
});

export const { showFeedbackNotification, hideFeedbackNotification } = feedbackNotificationReducer.actions;

export default feedbackNotificationReducer;
